/*
Template Name: Zircos Dashboard
Author: CoderThemes
Email: coderthemes@gmail.com
File: Menu
*/
/* =============
  == Components List==

   - Email
   - Maps
   - About Us
   - Contact Us
   - Member List
   - Timeline
   - Pages (Common)
   - Countdown
   - FAQ
   - Gallery
   - Pricing
   - Account Pages
   - Sitemaps (v1.1)
   - Search Results (v1.1)
   - Blog (v1.2)
   - Real Estate (v1.5)

============= */
/* =============
   Email
============= */
.mails {
  /* chat-widget */
}
.mails a {
  color: #797979;
}
.mails td {
  vertical-align: middle !important;
  position: relative;
  border: 0 !important;
}
.mails td:last-of-type {
  width: 100px;
  padding-right: 20px;
}
.mails tr:hover .text-white {
  display: none;
}
.mails .mail-select {
  padding: 15px 20px;
  min-width: 134px;
}
.mails .checkbox {
  margin-bottom: 0;
  margin-top: -4px;
  vertical-align: middle;
  display: inline-block;
  height: 17px;
}
.mails .checkbox label {
  min-height: 16px;
}
.mails .mail-list .list-group-item {
  background-color: transparent !important;
}
.mails .mail-list .list-group-item.active {
  background-color: #188ae2;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  background-clip: padding-box;
}
.mails .unread a {
  font-family: 'Hind Madurai', sans-serif;
  color: #313a46;
}
.mails .table-detail {
  vertical-align: top;
}
.mails .chat-right-text {
  overflow: hidden;
}
.mails .chat-widget .chat-item {
  overflow: hidden;
  padding: 10px 0;
  position: relative;
}
.mails .chat-widget .chat-item .chat-item-img {
  display: block;
  float: left;
  margin-right: 15px;
  width: 40px;
}
.mails .chat-widget .chat-item img {
  width: 40px;
}
.mails .chat-widget .chat-item .chat-item-author {
  color: #313a46;
  display: block;
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
}
.mails .chat-widget .chat-item .chat-item-text {
  color: #a0a0a0;
  display: block;
  font-size: 12px;
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
}
/* =============
   Maps
============= */
.gmaps,
.gmaps-panaroma {
  height: 300px;
  background: #eeeeee;
  border-radius: 3px;
}
.gmaps-overlay {
  display: block;
  text-align: center;
  color: #ffffff;
  font-size: 16px;
  line-height: 40px;
  background: #5d9cec;
  border-radius: 4px;
  padding: 10px 20px;
}
.gmaps-overlay_arrow {
  left: 50%;
  margin-left: -16px;
  width: 0;
  height: 0;
  position: absolute;
}
.gmaps-overlay_arrow.above {
  bottom: -15px;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-top: 16px solid #5d9cec;
}
.gmaps-overlay_arrow.below {
  top: -15px;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-bottom: 16px solid #5d9cec;
}
/* Vector Map */
.jvectormap-zoomin,
.jvectormap-zoomout {
  width: 10px;
  height: 10px;
  line-height: 10px;
}
.jvectormap-zoomout {
  top: 40px;
}
/* Mapael Map */
.mapael .map {
  position: relative;
}
.mapael .mapTooltip {
  position: absolute;
  background-color: #7fc1fc;
  opacity: 0.95;
  border-radius: 3px;
  padding: 2px 10px;
  z-index: 1000;
  max-width: 200px;
  display: none;
  color: #ffffff;
  font-family: 'Hind Madurai', sans-serif;
}
.mapael .zoomIn,
.mapael .zoomOut,
.mapael .zoomReset {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  border-radius: 2px;
  font-weight: 500;
  cursor: pointer;
  background-color: #26a69a;
  text-decoration: none;
  color: #ffffff;
  font-size: 14px;
  position: absolute;
  top: 0;
  left: 10px;
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.map .zoomIn {
  top: 25px;
}
.map .zoomOut {
  top: 50px;
}
/* =============
   About Us
============= */
.border {
  height: 4px;
  width: 48px;
  border-radius: 5px;
  background-color: #188ae2;
}
.about-features-box {
  margin: 20px 0;
}
.about-features-box p {
  line-height: 24px;
  width: 90%;
  margin: 0 auto;
}
.feature-icon {
  height: 80px;
  width: 80px;
  border: 2px solid #767D8E;
  margin: 0px auto;
  border-radius: 50%;
  font-size: 42px;
  line-height: 80px;
}
.about-team img {
  max-width: 150px;
  margin: 0 auto;
}
.about-team .about-team-member {
  margin: 30px 0px;
}
.about-team .about-team-member h4 {
  padding-top: 10px;
  font-weight: 600;
}
.about-team .about-team-member p {
  color: #98a6ad;
}
/* =============
   Contact Us
============= */
.contact-map {
  background-color: #f3f3f3;
}
.contact-box {
  padding: 30px;
}
.contact-detail {
  margin-bottom: 40px;
}
.contact-detail i {
  float: left;
  width: 32px;
  font-size: 20px;
}
.contact-detail p,
.contact-detail address {
  overflow: hidden;
}
.contact-detail a {
  color: #496174;
}
/* =============
   Members list
============= */
.member-card .member-thumb {
  position: relative;
}
.member-card .member-star {
  position: absolute;
  top: 12px;
  right: 10px;
  font-size: 16px;
  background-color: #ffffff;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
}
.social-links li a {
  border-radius: 50%;
  color: rgba(121, 121, 121, 0.5);
  display: inline-block;
  height: 30px;
  line-height: 30px;
  border: 2px solid rgba(121, 121, 121, 0.5);
  text-align: center;
  width: 30px;
}
.social-links li a:hover {
  color: #797979;
  border: 2px solid #797979;
}
/* =============
   Timeline
============= */
.timeline {
  border-collapse: collapse;
  border-spacing: 0;
  display: table;
  margin-bottom: 50px;
  position: relative;
  table-layout: fixed;
  width: 100%;
}
.timeline .time-show {
  margin-bottom: 30px;
  margin-right: -75px;
  margin-top: 30px;
  position: relative;
}
.timeline .time-show a {
  color: #ffffff;
}
.timeline:before {
  background-color: rgba(121, 121, 121, 0.3);
  bottom: 0;
  content: "";
  left: 50%;
  position: absolute;
  top: 30px;
  width: 2px;
  z-index: 0;
}
.timeline .timeline-icon {
  -webkit-border-radius: 50%;
  background: #797979;
  border-radius: 50%;
  color: #ffffff;
  display: block;
  height: 20px;
  left: -54px;
  margin-top: -10px;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 20px;
}
.timeline .timeline-icon i {
  color: #ffffff;
  font-size: 13px;
  margin-top: 4px;
  position: absolute;
  left: 4px;
}
.timeline .time-icon:before {
  font-size: 16px;
  margin-top: 5px;
}
h3.timeline-title {
  color: #797979;
  font-size: 20px;
  font-weight: 400;
  margin: 0 0 5px;
  text-transform: uppercase;
}
.timeline-item {
  display: table-row;
}
.timeline-item:before {
  content: "";
  display: block;
  width: 50%;
}
.timeline-item .timeline-desk .arrow {
  border-bottom: 12px solid transparent;
  border-right: 12px solid rgba(238, 238, 238, 0.3) !important;
  border-top: 12px solid transparent;
  display: block;
  height: 0;
  left: -12px;
  margin-top: -12px;
  position: absolute;
  top: 50%;
  width: 0;
}
.timeline-item .timeline-desk .timeline-box {
  padding: 20px;
}
.timeline-item .timeline-date {
  margin-bottom: 10px;
}
.timeline-item.alt:after {
  content: "";
  display: block;
  width: 50%;
}
.timeline-item.alt .timeline-desk .arrow-alt {
  border-bottom: 12px solid transparent;
  border-left: 12px solid rgba(238, 238, 238, 0.3) !important;
  border-top: 12px solid transparent;
  display: block;
  height: 0;
  left: auto;
  margin-top: -12px;
  position: absolute;
  right: -12px;
  top: 50%;
  width: 0;
}
.timeline-item.alt .timeline-desk .album {
  float: right;
  margin-top: 20px;
}
.timeline-item.alt .timeline-desk .album a {
  float: right;
  margin-left: 5px;
}
.timeline-item.alt .timeline-icon {
  left: auto;
  right: -56px;
}
.timeline-item.alt:before {
  display: none;
}
.timeline-item.alt .panel {
  margin-left: 0;
  margin-right: 45px;
}
.timeline-item.alt h4 {
  text-align: right;
}
.timeline-item.alt p {
  text-align: right;
}
.timeline-item.alt .timeline-date {
  text-align: right;
}
.timeline-desk {
  display: table-cell;
  vertical-align: top;
  width: 50%;
}
.timeline-desk h4 {
  font-size: 16px;
  font-weight: 300;
  margin: 0;
}
.timeline-desk .panel {
  background: rgba(238, 238, 238, 0.25);
  display: block;
  margin-bottom: 5px;
  margin-left: 45px;
  position: relative;
  text-align: left;
  border: 0;
}
.timeline-desk h5 span {
  color: #797979;
  display: block;
  font-size: 12px;
  margin-bottom: 4px;
}
.timeline-desk p {
  color: #999999;
  font-size: 14px;
  margin-bottom: 0;
}
.timeline-desk .album {
  margin-top: 12px;
}
.timeline-desk .album a {
  float: left;
  margin-right: 5px;
}
.timeline-desk .album img {
  height: 36px;
  width: auto;
  border-radius: 3px;
}
.timeline-desk .notification {
  background: none repeat scroll 0 0 #ffffff;
  margin-top: 20px;
  padding: 8px;
}
.timeline-left {
  margin-left: 20px;
  width: auto;
  display: block;
}
.timeline-left:before {
  left: 0 !important;
}
.timeline-left .timeline-item {
  display: block;
}
.timeline-left .timeline-desk {
  display: block;
  width: 100%;
}
.timeline-left .panel {
  margin-bottom: 20px;
}
/* =============
   Pages - Common
============= */
/* Pages */
.account-pages-bg {
  background: url("../images/dust.png");
}
.home-wrapper {
  margin: 10% 0;
}
/* =============
   Count Down
============= */
#count-down {
  margin-top: 50px;
}
#count-down .clock-presenter {
  height: 140px;
  line-height: 30px;
  padding: 0px 30px;
  text-align: center;
}
#count-down .clock-presenter .digit {
  margin-top: 20px;
  font-size: 60px;
  font-family: 'Hind Madurai', sans-serif;
  line-height: 60px;
  height: 60px;
  display: inline-block;
  overflow: hidden;
  text-align: center;
  position: relative;
  cursor: default;
}
#count-down .clock-presenter .note {
  position: relative;
  bottom: 0px;
  padding-top: 5px;
  cursor: default;
  font-size: 16px;
  color: #4bd396;
  font-family: 'Hind Madurai', sans-serif;
  text-transform: uppercase;
}
@media (max-width: 992px) {
  #count-down .clock-presenter .digit {
    font-size: 42px;
  }
}
@media (max-width: 767px) {
  #count-down .clock-presenter {
    width: 50%;
    float: left;
  }
  #count-down .clock-presenter .digit {
    font-size: 36px;
  }
  #count-down .hours_dash {
    border-right: none;
  }
}
/* =============
   FAQ
============= */
.question-q-box {
  height: 30px;
  width: 30px;
  color: #ffffff;
  background-color: #f5707a;
  text-align: center;
  border-radius: 3px;
  float: left;
  line-height: 30px;
}
.question {
  margin-top: 0;
  margin-left: 50px;
  font-weight: 400;
  font-size: 16px;
}
.answer {
  margin-left: 50px;
  color: #98a6ad;
  margin-bottom: 40px;
}
/* =============
   Gallery
============= */
.portfolioFilter a {
  transition: all 0.3s ease-out;
  color: #313a46;
  font-weight: 600;
  font-family: 'Hind Madurai', sans-serif;
  padding: 5px 10px;
  display: inline-block;
  margin-bottom: 5px;
}
.portfolioFilter a:hover,
.portfolioFilter a.current {
  color: #4bd396;
}
.thumb {
  border-radius: 3px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  width: 100%;
  overflow: hidden;
}
.thumb:hover .thumb-img {
  overflow: hidden;
  position: relative;
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -o-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}
.thumb-img {
  border-radius: 2px;
  overflow: hidden;
  width: 100%;
  transition: all 0.2s ease-out;
}
.gal-detail {
  padding: 10px;
  position: relative;
  background-color: #ffffff;
}
.gal-detail h4 {
  font-weight: 600;
  font-size: 16px;
  padding: 0 5px;
}
.gal-detail p {
  padding: 0 5px;
  font-size: 13px;
}
/* =============
   Pricing
============= */
.pricing-column {
  position: relative;
  margin-bottom: 40px;
}
.pricing-column .inner-box {
  position: relative;
  padding: 0 0 50px;
}
.pricing-column .plan-header {
  position: relative;
  padding: 30px 20px 25px;
}
.pricing-column .plan-title {
  font-size: 16px;
  font-family: 'Hind Madurai', sans-serif;
  margin-bottom: 10px;
  color: #4bd396;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 400;
}
.pricing-column .plan-price {
  font-size: 48px;
  font-family: 'Hind Madurai', sans-serif;
  margin-bottom: 10px;
  color: #313a46;
}
.pricing-column .plan-duration {
  font-size: 13px;
  color: #98a6ad;
}
.pricing-column .plan-stats {
  position: relative;
  padding: 30px 20px 15px;
}
.pricing-column .plan-stats li {
  margin-bottom: 15px;
  line-height: 24px;
}
.ribbon {
  position: absolute;
  left: 5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
  font-family: 'Hind Madurai', sans-serif;
}
.ribbon span {
  font-size: 10px;
  font-weight: bold;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  width: 100px;
  display: block;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
  background: #7fc1fc;
  background: linear-gradient(#188ae2 0%, #188ae2 100%);
  position: absolute;
  top: 19px;
  letter-spacing: 1px;
  left: -21px;
}
.ribbon span:before {
  content: "";
  position: absolute;
  left: 0;
  top: 100%;
  z-index: -1;
  border-left: 3px solid #136eb4;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #136eb4;
}
.ribbon span:after {
  content: "";
  position: absolute;
  right: 0;
  top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid #136eb4;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #136eb4;
}
/* =============
   Account Pages
============= */
.wrapper-page {
  margin: 5% auto;
  position: relative;
  max-width: 420px;
}
.account-pages {
  box-shadow: 0 0px 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);
  border-radius: 5px;
}
.account-pages .account-content {
  padding: 30px;
}
.account-pages .account-btn {
  position: absolute;
  left: 0;
  right: 0;
}
.account-logo-box {
  background-color: #505458;
  padding: 10px;
  border-radius: 5px 5px 0 0;
}
.checkmark {
  width: 120px;
  margin: 0 auto;
  padding: 20px 0;
}
.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
  animation: dash 2s ease-in-out;
  -webkit-animation: dash 2s ease-in-out;
}
.spin {
  animation: spin 2s;
  -webkit-animation: spin 2s;
  transform-origin: 50% 50%;
  -webkit-transform-origin: 50% 50%;
}
@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes text {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes text {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* =============
   Sitemaps
============= */
.sitemap > li > ul {
  margin-top: 1.5rem;
  padding-left: 0;
}
ul.sitemap {
  list-style: none;
  padding-left: 0;
}
ul.sitemap li {
  line-height: 2.5rem ;
  vertical-align: top ;
  list-style: none;
  position: relative ;
}
ul.sitemap li a {
  text-decoration: none ;
  color: #7c8d96;
  display: inline-block;
}
ul.sitemap li a:hover {
  color: #7fc1fc;
}
ul.sitemap ul {
  margin-left: 1.5rem ;
  margin-bottom: 1.5rem ;
  padding-top: 10px;
}
ul.sitemap ul li {
  position: relative ;
}
ul.sitemap ul li::before {
  content: "";
  display: inline-block;
  width: 3rem;
  height: 100% ;
  border-left: 1px solid rgba(152, 166, 173, 0.5);
  position: absolute ;
  top: -1.25;
}
ul.sitemap ul li::before {
  content: "";
  display: inline-block;
  width: 3rem;
  height: 2.5rem ;
  border-bottom: 1px #ccc solid ;
  position: absolute ;
  top: -1.25rem;
}
ul.sitemap ul li a {
  margin-left: 3.75rem;
}
/* =============
   Search Results
============= */
.search-result-box .search-item {
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(152, 166, 173, 0.2);
  margin-bottom: 25px;
}
/* =============
   Blogs
============= */
.blog-list-wrapper {
  max-width: 1170px;
  margin: 0 auto;
}
.blog-post .post-image {
  position: relative;
  margin-bottom: 20px;
}
.blog-post .post-image .label {
  position: absolute;
  bottom: 8px;
  left: 10px;
  text-transform: uppercase;
}
.blog-post .post-title a {
  color: #188ae2;
  line-height: 28px;
}
.blog-post .post-title a:hover {
  color: #136eb4;
}
.blog-categories-list li {
  display: block;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ededed;
}
.blog-categories-list li a {
  display: block;
  color: rgba(49, 58, 70, 0.7);
}
.blog-categories-list li a:hover {
  color: #f5707a;
}
.latest-post-item h5 a {
  color: rgba(49, 58, 70, 0.6);
  line-height: 20px;
}
.blog-post-column {
  box-shadow: 0 8px 42px 0 rgba(0, 0, 0, 0.08);
}
.blog-post-column .post-image {
  margin-bottom: 0;
}
.blog-post-comment .media {
  margin-bottom: 20px;
  padding-bottom: 20px;
}
.blog-post-comment .media img {
  height: 52px;
  width: 52px;
}
.blog-post-comment .media .media {
  margin-bottom: 0;
  border-bottom: 0;
  padding-top: 20px;
  padding-bottom: 0;
}
/* ==========
Real Estate
============ */
.property-card {
  box-sizing: border-box;
  margin-bottom: 30px;
  background-clip: padding-box;
  box-shadow: 0 0 11px 0 rgba(49, 58, 70, 0.15);
}
.property-card .property-content {
  position: relative;
  box-sizing: border-box;
  padding: 15px;
  border-radius: 0 0 2px 2px;
  background-clip: padding-box;
}
.property-card .property-image {
  display: block;
  height: 230px;
  position: relative;
  overflow: hidden;
}
.property-card .property-action {
  padding: 12px 15px;
  border-top: 1px solid rgba(160, 160, 160, 0.2);
}
.property-card .property-label {
  position: absolute;
  top: 10px;
  right: 10px;
  text-transform: uppercase;
}
.property-card.property-horizontal .property-content {
  width: 100%;
  height: 230px;
}
.property-card.property-horizontal .property-content .listingInfo {
  display: table-cell;
  height: 155px;
  vertical-align: middle;
}
.property-card.property-horizontal .property-content .property-action {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}
.property-card.property-horizontal .property-content .property-action a {
  margin-right: 7px;
  color: #313a46;
  -webkit-transition: color .3s ease;
  transition: color .3s ease;
  display: inline-block;
  border: 1px solid #dcdee0;
  padding: 1px 6px;
  border-radius: 3px;
}
.property-card.property-horizontal .property-content .property-action a i {
  font-size: 16px;
  margin-right: 5px;
  vertical-align: middle;
}
/* Property detail page */
.property-detail-wrapper {
  max-width: 1170px;
  margin: 0 auto;
}
.property-detail-wrapper .bx-wrapper {
  margin-bottom: 0;
}
.property-detail-wrapper .bx-wrapper .bx-next {
  right: 20px;
}
.property-detail-wrapper .bx-wrapper .bx-controls-direction a {
  z-index: 9;
}
.property-detail-wrapper #bx-pager a.active img {
  border: 1px solid #7fc1fc;
}
.property-detail-wrapper #bx-pager a img {
  padding: 3px;
  border: solid #ccc 1px;
}
.property-detail-wrapper .proprerty-features li {
  line-height: 30px;
}
.property-detail-wrapper #map-property {
  height: 300px;
  background: #69c;
}
