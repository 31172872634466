/*
Template Name: Zircos Dashboard
Author: CoderThemes
Email: coderthemes@gmail.com
File: Components
*/
/* =============
  == Components List==

   - Popover / Tooltips
   - Buttons
   - Panels
   - Portlets
   - Checkbox and radio
   - Modals
   - Tabs
   - Progressbars
   - Notifications
   - Alerts
   - Carousel
   - Sweet Alert
   - Widgets
   - Nestable
   - Rating
   - Calendar
   - Form
   - Tables
   - Charts

============= */
/* =================
   Popover / Tooltips
==================== */
/* Popover */
.popover {
  font-family: inherit;
  border: none;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  background-clip: padding-box;
  box-shadow: 0 0 28px rgba(0, 0, 0, 0.15);
}
.popover .popover-title {
  background-color: transparent;
  color: #7fc1fc;
  padding: 12px 15px;
  font-size: 15px;
}
.popover .arrow {
  border-color: transparent !important;
}
/* Tooltips */
.tooltip {
  font-family: 'Hind Madurai', sans-serif;
}
.tooltip .tooltip-inner {
  padding: 4px 10px;
  border-radius: 2px;
  background-color: #313a46;
}
.tooltip.left .tooltip-arrow {
  border-left-color: #313a46;
}
.tooltip.top .tooltip-arrow {
  border-top-color: #313a46;
}
.tooltip.bottom .tooltip-arrow {
  border-bottom-color: #313a46;
}
.tooltip.right .tooltip-arrow {
  border-right-color: #313a46;
}
/* Tooltpster */
.tooltipster-sidetip .tooltipster-box {
  background-color: #7fc1fc;
  border: 2px solid #7fc1fc;
}
.tooltipster-sidetip.tooltipster-top .tooltipster-arrow-border,
.tooltipster-sidetip.tooltipster-top .tooltipster-arrow-background {
  border-top-color: #7fc1fc;
}
.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow-border,
.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow-background {
  border-bottom-color: #7fc1fc;
}
.tooltipster-sidetip.tooltipster-left .tooltipster-arrow-border,
.tooltipster-sidetip.tooltipster-left .tooltipster-arrow-background {
  border-left-color: #7fc1fc;
}
.tooltipster-sidetip.tooltipster-right .tooltipster-arrow-border,
.tooltipster-sidetip.tooltipster-right .tooltipster-arrow-background {
  border-right-color: #7fc1fc;
}
/* =============
   Buttons
============= */
.btn {
  border-radius: 2px;
  padding: 6px 14px;
}
.btn-md {
  padding: 8px 18px;
}
.btn-group-lg > .btn,
.btn-lg {
  padding: 10px 16px !important;
  font-size: 16px;
}
.btn-group-sm > .btn,
.btn-sm {
  padding: 5px 10px !important;
}
.btn-group-xs > .btn,
.btn-xs {
  padding: 1px 5px !important;
}
.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group {
  margin-left: 0px;
}
.btn-group.open .dropdown-toggle {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
}
.btn-custom,
.btn-primary,
.btn-success,
.btn-info,
.btn-warning,
.btn-danger,
.btn-inverse,
.btn-purple,
.btn-pink,
.btn-orange,
.btn-brown,
.btn-teal {
  color: #ffffff !important;
}
.btn-custom {
  background-color: #7fc1fc;
  border-color: #7fc1fc;
}
.btn-custom:hover,
.btn-custom:focus,
.btn-custom:active,
.btn-custom.active,
.btn-custom.focus,
.btn-custom:active,
.btn-custom:focus,
.btn-custom:hover,
.open > .dropdown-toggle.btn-custom {
  background-color: #66b5fb !important;
  border: 1px solid #66b5fb !important;
}
.btn-default {
  background-color: #ffffff;
  border-color: rgba(49, 58, 70, 0.2);
}
.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.btn-default.focus,
.btn-default:active,
.btn-default:focus,
.btn-default:hover,
.open > .dropdown-toggle.btn-default {
  background-color: rgba(49, 58, 70, 0.07) !important;
  border: 1px solid rgba(49, 58, 70, 0.2) !important;
}
.btn-primary {
  background-color: #188ae2 !important;
  border: 1px solid #188ae2 !important;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.btn-primary.focus,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover,
.open > .dropdown-toggle.btn-primary {
  background-color: #167ccb !important;
  border: 1px solid #167ccb !important;
}
.btn-success {
  background-color: #4bd396 !important;
  border: 1px solid #4bd396 !important;
}
.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.btn-success.focus,
.btn-success:active,
.btn-success:focus,
.btn-success:hover,
.open > .dropdown-toggle.btn-success {
  background-color: #37ce8a !important;
  border: 1px solid #37ce8a !important;
}
.btn-info {
  background-color: #3ac9d6 !important;
  border: 1px solid #3ac9d6 !important;
}
.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.btn-info.focus,
.btn-info:active,
.btn-info:focus,
.btn-info:hover,
.open > .dropdown-toggle.btn-info {
  background-color: #2abfcc !important;
  border: 1px solid #2abfcc !important;
}
.btn-warning {
  background-color: #f9c851 !important;
  border: 1px solid #f9c851 !important;
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.btn-warning.focus,
.btn-warning:active,
.btn-warning:focus,
.btn-warning:hover,
.open > .dropdown-toggle.btn-warning {
  background-color: #f8c038 !important;
  border: 1px solid #f8c038 !important;
}
.btn-danger {
  background-color: #f5707a !important;
  border: 1px solid #f5707a !important;
}
.btn-danger:active,
.btn-danger:focus,
.btn-danger:hover,
.btn-danger.active,
.btn-danger.focus,
.btn-danger:active,
.btn-danger:focus,
.btn-danger:hover,
.open > .dropdown-toggle.btn-danger {
  background-color: #f35864 !important;
  border: 1px solid #f35864 !important;
}
.btn-inverse {
  background-color: #3b3e47 !important;
  border: 1px solid #3b3e47 !important;
}
.btn-inverse:hover,
.btn-inverse:focus,
.btn-inverse:active,
.btn-inverse.active,
.btn-inverse.focus,
.btn-inverse:active,
.btn-inverse:focus,
.btn-inverse:hover,
.open > .dropdown-toggle.btn-inverse {
  background-color: #2f3239 !important;
  border: 1px solid #2f3239 !important;
}
.btn-purple {
  background-color: #6b5fb5 !important;
  border: 1px solid #6b5fb5 !important;
}
.btn-purple:hover,
.btn-purple:focus,
.btn-purple:active {
  background-color: #5c4fab !important;
  border: 1px solid #5c4fab !important;
}
.btn-pink {
  background-color: #f06292 !important;
  border: 1px solid #f06292 !important;
}
.btn-pink:hover,
.btn-pink:focus,
.btn-pink:active {
  background-color: #ee4b82 !important;
  border: 1px solid #ee4b82 !important;
}
.btn-orange {
  background-color: #ff9800 !important;
  border: 1px solid #ff9800 !important;
}
.btn-orange:hover,
.btn-orange:focus,
.btn-orange:active {
  background-color: #e68900 !important;
  border: 1px solid #e68900 !important;
}
.btn-brown {
  background-color: #8d6e63 !important;
  border: 1px solid #8d6e63 !important;
}
.btn-brown:hover,
.btn-brown:focus,
.btn-brown:active {
  background-color: #7e6258 !important;
  border: 1px solid #7e6258 !important;
}
.btn-teal {
  background-color: #26a69a !important;
  border: 1px solid #26a69a !important;
}
.btn-teal:hover,
.btn-teal:focus,
.btn-teal:active {
  background-color: #219187 !important;
  border: 1px solid #219187 !important;
}
.btn-bordered {
  border-bottom: 3px solid transparent;
}
.btn-bordered.btn-default {
  background-color: #ffffff;
  border-bottom: 2px solid rgba(49, 58, 70, 0.1) !important;
}
.btn-bordered.btn-custom {
  background-color: #7fc1fc;
  border-bottom: 2px solid #5cb0fb !important;
}
.btn-bordered.btn-primary {
  border-bottom: 2px solid #1576c2 !important;
}
.btn-bordered.btn-success {
  border-bottom: 2px solid #31c985 !important;
}
.btn-bordered.btn-info {
  border-bottom: 2px solid #29b7c4 !important;
}
.btn-bordered.btn-warning {
  border-bottom: 2px solid #f7b820 !important;
}
.btn-bordered.btn-danger {
  border-bottom: 2px solid #f2404e !important;
}
.btn-bordered.btn-inverse {
  border-bottom: 2px solid #0d0d0f !important;
}
.btn-bordered.btn-purple {
  border-bottom: 2px solid #584ca4 !important;
}
.btn-bordered.btn-pink {
  border-bottom: 2px solid #ed417b !important;
}
.btn-bordered.btn-orange {
  border-bottom: 2px solid #db8300 !important;
}
.btn-bordered.btn-brown {
  border-bottom: 2px solid #785e54 !important;
}
.btn-bordered.btn-teal {
  border-bottom: 2px solid #1f897f !important;
}
.btn-rounded {
  border-radius: 2em;
  padding: 6px 18px;
}
/* Social Buttons */
.btn-facebook {
  color: #ffffff !important;
  background-color: #3b5998 !important;
}
.btn-twitter {
  color: #ffffff !important;
  background-color: #00aced !important;
}
.btn-linkedin {
  color: #ffffff !important;
  background-color: #007bb6 !important;
}
.btn-dribbble {
  color: #ffffff !important;
  background-color: #ea4c89 !important;
}
.btn-googleplus {
  color: #ffffff !important;
  background-color: #dd4b39 !important;
}
.btn-instagram {
  color: #ffffff !important;
  background-color: #517fa4 !important;
}
.btn-pinterest {
  color: #ffffff !important;
  background-color: #cb2027 !important;
}
.btn-dropbox {
  color: #ffffff !important;
  background-color: #007ee5 !important;
}
.btn-flickr {
  color: #ffffff !important;
  background-color: #ff0084 !important;
}
.btn-tumblr {
  color: #ffffff !important;
  background-color: #32506d !important;
}
.btn-skype {
  color: #ffffff !important;
  background-color: #00aff0 !important;
}
.btn-youtube {
  color: #ffffff !important;
  background-color: #bb0000 !important;
}
.btn-github {
  color: #ffffff !important;
  background-color: #171515 !important;
}
/* ===========
   Panels
 =============*/
.panel {
  border: 2px solid #f3f3f3;
  box-shadow: none;
  margin-bottom: 20px;
}
.panel .panel-body {
  padding: 20px;
}
.panel .panel-body p {
  margin-bottom: 0;
  line-height: 24px;
}
.panel .panel-body p + p {
  padding-top: 10px;
}
.panel-heading {
  border: none !important;
  padding: 15px 20px;
  margin: -2px;
  border-radius: 4px 4px 0 0;
}
.panel-default > .panel-heading {
  background-color: #f3f3f3;
  border-bottom: none;
  color: #797979;
}
.panel-title {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 0;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  font-family: 'Hind Madurai', sans-serif;
}
.panel-sub-title {
  margin-bottom: 0px;
  color: rgba(255, 255, 255, 0.7) !important;
  margin-top: 3px;
}
.panel-footer {
  background: #f3f3f3;
  border-top: 0;
}
.panel-default .panel-sub-title {
  color: inherit !important;
}
.panel-color .panel-title {
  color: #ffffff;
}
.panel-primary > .panel-heading {
  background-color: #188ae2;
}
.panel-success > .panel-heading {
  background-color: #4bd396;
}
.panel-info > .panel-heading {
  background-color: #3ac9d6;
}
.panel-warning > .panel-heading {
  background-color: #f9c851;
}
.panel-danger > .panel-heading {
  background-color: #f5707a;
}
.panel-purple > .panel-heading {
  background-color: #6b5fb5;
}
.panel-pink > .panel-heading {
  background-color: #f06292;
}
.panel-orange > .panel-heading {
  background-color: #ff9800;
}
.panel-brown > .panel-heading {
  background-color: #8d6e63;
}
.panel-teal > .panel-heading {
  background-color: #26a69a;
}
.panel-inverse > .panel-heading {
  background-color: #3b3e47;
}
.panel-border {
  border-radius: 3px;
}
.panel-border .panel-heading {
  background-color: #ffffff;
  border-top: 3px solid #ccc !important;
  border-radius: 3px;
  padding: 15px 20px 0;
  margin: -2px 0 0 0 !important;
}
.panel-border .panel-body {
  padding: 15px 20px 20px 20px;
}
.panel-border.panel-primary .panel-heading {
  border-color: #188ae2 !important;
  color: #188ae2 !important;
}
.panel-border.panel-success .panel-heading {
  border-color: #4bd396 !important;
  color: #4bd396 !important;
}
.panel-border.panel-info .panel-heading {
  border-color: #3ac9d6 !important;
  color: #3ac9d6 !important;
}
.panel-border.panel-warning .panel-heading {
  border-color: #f9c851 !important;
  color: #f9c851 !important;
}
.panel-border.panel-danger .panel-heading {
  border-color: #f5707a !important;
  color: #f5707a !important;
}
.panel-border.panel-purple .panel-heading {
  border-color: #6b5fb5 !important;
  color: #6b5fb5 !important;
}
.panel-border.panel-pink .panel-heading {
  border-color: #f06292 !important;
  color: #f06292 !important;
}
.panel-border.panel-orange .panel-heading {
  border-color: #ff9800 !important;
  color: #ff9800 !important;
}
.panel-border.panel-brown .panel-heading {
  border-color: #8d6e63 !important;
  color: #8d6e63 !important;
}
.panel-border.panel-teal .panel-heading {
  border-color: #26a69a !important;
  color: #26a69a !important;
}
.panel-border.panel-inverse .panel-heading {
  border-color: #3b3e47 !important;
  color: #3b3e47 !important;
}
.panel-default > .panel-heading + .panel-collapse > .panel-body {
  border-top: 0 !important;
}
/* ===========
   Portlets
 =============*/
.portlet {
  background: #ffffff;
  border-radius: 3px;
  margin-bottom: 20px;
  transition: all 0.4s;
  border: 2px solid #f3f3f3;
}
.portlet .portlet-heading {
  -webkit-border-radius: 3px 3px 0 0;
  border-radius: 3px 3px 0 0;
  -moz-border-radius: 3px 3px 0 0;
  color: #ffffff;
  padding: 12px 20px;
  margin: -2px -2px 0 -2px;
}
.portlet .portlet-heading .portlet-title {
  color: #ffffff;
  float: left;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 9px;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  font-family: 'Hind Madurai', sans-serif;
}
.portlet .portlet-heading .portlet-widgets {
  display: inline-block;
  float: right;
  font-size: 15px;
  line-height: 30px;
  padding-left: 15px;
  position: relative;
  text-align: right;
}
.portlet .portlet-heading .portlet-widgets .divider {
  margin: 0 5px;
}
.portlet .portlet-heading a {
  color: #999999;
}
.portlet .portlet-body {
  -moz-border-radius-bottomleft: 5px;
  -moz-border-radius-bottomright: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  background: #ffffff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 15px;
}
.portlet-default .portlet-title {
  color: #797979 !important;
}
.portlet .portlet-heading .portlet-widgets .collapsed .ion-minus-round:before {
  content: "\f217" !important;
}
.portlet .portlet-heading.bg-purple a,
.portlet .portlet-heading.bg-info a,
.portlet .portlet-heading.bg-success a,
.portlet .portlet-heading.bg-primary a,
.portlet .portlet-heading.bg-danger a,
.portlet .portlet-heading.bg-warning a,
.portlet .portlet-heading.bg-inverse a,
.portlet .portlet-heading.bg-pink a,
.portlet .portlet-heading.bg-orange a,
.portlet .portlet-heading.bg-brown a,
.portlet .portlet-heading.bg-teal a {
  color: #ffffff;
}
.panel-disabled {
  background: rgba(49, 58, 70, 0.5);
  cursor: progress;
  bottom: 20px;
  border-radius: 3px;
  left: 10px;
  position: absolute;
  right: 10px;
  top: 0;
}
.portlet-loader {
  width: 30px;
  height: 30px;
  background-color: #313a46;
  border-radius: 2px;
  -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
  animation: sk-rotateplane 1.2s infinite ease-in-out;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -15px;
  margin-top: -15px;
}
@-webkit-keyframes sk-rotateplane {
  0% {
    -webkit-transform: perspective(120px);
  }
  50% {
    -webkit-transform: perspective(120px) rotateY(180deg);
  }
  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
  }
}
@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}
/* ==== Draggable card ==== */
.card-draggable-placeholder {
  border: 2px dashed rgba(67, 89, 102, 0.5);
  margin-bottom: 16px;
  background-color: rgba(67, 89, 102, 0.08);
}
/* =============
   Checkbox and Radios
============= */
.checkbox {
  padding-left: 20px;
}
.checkbox label {
  display: inline-block;
  padding-left: 5px;
  position: relative;
  font-family: 'Varela Round', sans-serif;
  font-weight: normal;
}
.checkbox label::before {
  -o-transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  background-color: #ffffff;
  border-radius: 2px;
  border: 1px solid #dadada;
  content: "";
  display: inline-block;
  height: 17px;
  left: 0;
  margin-left: -20px;
  position: absolute;
  transition: 0.3s ease-in-out;
  width: 17px;
  outline: none !important;
  margin-top: 2px;
}
.checkbox label::after {
  color: #797979;
  display: inline-block;
  font-size: 11px;
  height: 16px;
  left: 1px;
  margin-left: -20px;
  padding-left: 3px;
  padding-top: 1px;
  position: absolute;
  top: 2px;
  width: 16px;
}
.checkbox input[type="checkbox"] {
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  outline: none !important;
}
.checkbox input[type="checkbox"]:disabled + label {
  opacity: 0.65;
}
.checkbox input[type="checkbox"]:focus + label::before {
  outline-offset: -2px;
  outline: none;
}
.checkbox input[type="checkbox"]:checked + label::after {
  content: '\f26b';
  font-family: 'Material-Design-Iconic-Font';
  font-weight: bold;
}
.checkbox input[type="checkbox"]:disabled + label::before {
  background-color: #eeeeee;
  cursor: not-allowed;
}
.checkbox.checkbox-circle label::before {
  border-radius: 50%;
}
.checkbox.checkbox-inline {
  margin-top: 0;
}
.checkbox.checkbox-single label {
  height: 17px;
}
.checkbox-custom input[type="checkbox"]:checked + label::before {
  background-color: #7fc1fc;
  border-color: #7fc1fc;
}
.checkbox-custom input[type="checkbox"]:checked + label::after {
  color: #ffffff;
}
.checkbox-primary input[type="checkbox"]:checked + label::before {
  background-color: #188ae2;
  border-color: #188ae2;
}
.checkbox-primary input[type="checkbox"]:checked + label::after {
  color: #ffffff;
}
.checkbox-danger input[type="checkbox"]:checked + label::before {
  background-color: #f5707a;
  border-color: #f5707a;
}
.checkbox-danger input[type="checkbox"]:checked + label::after {
  color: #ffffff;
}
.checkbox-info input[type="checkbox"]:checked + label::before {
  background-color: #3ac9d6;
  border-color: #3ac9d6;
}
.checkbox-info input[type="checkbox"]:checked + label::after {
  color: #ffffff;
}
.checkbox-warning input[type="checkbox"]:checked + label::before {
  background-color: #f9c851;
  border-color: #f9c851;
}
.checkbox-warning input[type="checkbox"]:checked + label::after {
  color: #ffffff;
}
.checkbox-success input[type="checkbox"]:checked + label::before {
  background-color: #4bd396;
  border-color: #4bd396;
}
.checkbox-success input[type="checkbox"]:checked + label::after {
  color: #ffffff;
}
.checkbox-purple input[type="checkbox"]:checked + label::before {
  background-color: #6b5fb5;
  border-color: #6b5fb5;
}
.checkbox-purple input[type="checkbox"]:checked + label::after {
  color: #ffffff;
}
.checkbox-pink input[type="checkbox"]:checked + label::before {
  background-color: #f06292;
  border-color: #f06292;
}
.checkbox-pink input[type="checkbox"]:checked + label::after {
  color: #ffffff;
}
.checkbox-inverse input[type="checkbox"]:checked + label::before {
  background-color: #3b3e47;
  border-color: #3b3e47;
}
.checkbox-inverse input[type="checkbox"]:checked + label::after {
  color: #ffffff;
}
/* Radios */
.radio {
  padding-left: 20px;
}
.radio label {
  display: inline-block;
  padding-left: 5px;
  position: relative;
  font-family: 'Varela Round', sans-serif;
  font-weight: normal;
}
.radio label::before {
  -o-transition: border 0.5s ease-in-out;
  -webkit-transition: border 0.5s ease-in-out;
  background-color: #ffffff;
  border-radius: 50%;
  border: 1px solid #dadada;
  content: "";
  display: inline-block;
  height: 17px;
  left: 0;
  margin-left: -20px;
  outline: none !important;
  position: absolute;
  transition: border 0.5s ease-in-out;
  width: 17px;
}
.radio label::after {
  -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -ms-transform: scale(0, 0);
  -o-transform: scale(0, 0);
  -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -webkit-transform: scale(0, 0);
  -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  background-color: #797979;
  border-radius: 50%;
  content: " ";
  display: inline-block;
  height: 11px;
  left: 3px;
  margin-left: -20px;
  position: absolute;
  top: 3px;
  transform: scale(0, 0);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  width: 11px;
}
.radio input[type="radio"] {
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  outline: none !important;
}
.radio input[type="radio"]:disabled + label {
  opacity: 0.65;
}
.radio input[type="radio"]:focus + label::before {
  outline-offset: -2px;
}
.radio input[type="radio"]:checked + label::after {
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}
.radio input[type="radio"]:disabled + label::before {
  cursor: not-allowed;
}
.radio.radio-inline {
  margin-top: 0;
}
.radio.radio-single label {
  height: 17px;
}
.radio-custom input[type="radio"] + label::after {
  background-color: #7fc1fc;
}
.radio-custom input[type="radio"]:checked + label::before {
  border-color: #7fc1fc;
}
.radio-custom input[type="radio"]:checked + label::after {
  background-color: #7fc1fc;
}
.radio-primary input[type="radio"] + label::after {
  background-color: #188ae2;
}
.radio-primary input[type="radio"]:checked + label::before {
  border-color: #188ae2;
}
.radio-primary input[type="radio"]:checked + label::after {
  background-color: #188ae2;
}
.radio-danger input[type="radio"] + label::after {
  background-color: #f5707a;
}
.radio-danger input[type="radio"]:checked + label::before {
  border-color: #f5707a;
}
.radio-danger input[type="radio"]:checked + label::after {
  background-color: #f5707a;
}
.radio-info input[type="radio"] + label::after {
  background-color: #3ac9d6;
}
.radio-info input[type="radio"]:checked + label::before {
  border-color: #3ac9d6;
}
.radio-info input[type="radio"]:checked + label::after {
  background-color: #3ac9d6;
}
.radio-warning input[type="radio"] + label::after {
  background-color: #f9c851;
}
.radio-warning input[type="radio"]:checked + label::before {
  border-color: #f9c851;
}
.radio-warning input[type="radio"]:checked + label::after {
  background-color: #f9c851;
}
.radio-success input[type="radio"] + label::after {
  background-color: #4bd396;
}
.radio-success input[type="radio"]:checked + label::before {
  border-color: #4bd396;
}
.radio-success input[type="radio"]:checked + label::after {
  background-color: #4bd396;
}
.radio-purple input[type="radio"] + label::after {
  background-color: #6b5fb5;
}
.radio-purple input[type="radio"]:checked + label::before {
  border-color: #6b5fb5;
}
.radio-purple input[type="radio"]:checked + label::after {
  background-color: #6b5fb5;
}
.radio-pink input[type="radio"] + label::after {
  background-color: #f06292;
}
.radio-pink input[type="radio"]:checked + label::before {
  border-color: #f06292;
}
.radio-pink input[type="radio"]:checked + label::after {
  background-color: #f06292;
}
/* =============
   Modals
============= */
.modal .modal-dialog .modal-content {
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  border-color: #DDDDDD;
  border-radius: 2px;
  box-shadow: none;
  padding: 25px;
}
.modal .modal-dialog .modal-content .modal-header {
  border-bottom-width: 2px;
  margin: 0;
  padding: 0;
  padding-bottom: 15px;
}
.modal .modal-dialog .modal-content .modal-body {
  padding: 20px 0;
}
.modal .modal-dialog .modal-content .modal-footer {
  padding: 0;
  padding-top: 15px;
}
.modal-full {
  width: 98%;
}
.modal-content .nav.nav-tabs + .tab-content {
  margin-bottom: 0;
}
.modal-content .panel-group {
  margin-bottom: 0;
}
.modal-content .panel {
  border-top: none;
}
/* Custom-modal */
.modal-demo {
  background-color: #ffffff;
  width: 600px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  background-clip: padding-box;
  display: none;
}
.modal-demo .close {
  position: absolute;
  top: 15px;
  right: 25px;
  color: #eeeeee;
}
.custom-modal-title {
  padding: 15px 25px 15px 25px;
  line-height: 22px;
  font-size: 18px;
  background-color: #313a46;
  color: #ffffff;
  text-align: left;
  margin: 0;
}
.custom-modal-text {
  padding: 20px;
}
.custombox-modal-flash .close,
.custombox-modal-rotatedown .close {
  top: 20px;
  z-index: 9999;
}
/* =============
   Tabs
============= */
.tab-content {
  padding: 20px 0 0 0;
}
.nav-tabs > li > a {
  color: #313a46;
  text-transform: uppercase;
  font-weight: 600;
  font-family: 'Hind Madurai', sans-serif;
}
.nav-tabs > li > a:hover {
  background-color: rgba(152, 166, 173, 0.1);
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
  color: #7fc1fc;
}
/* Vertial tab */
.tabs-vertical-env .tab-content {
  background: #ffffff;
  display: table-cell;
  padding: 0 0 0 20px;
  vertical-align: top;
}
.tabs-vertical-env .nav.tabs-vertical {
  display: table-cell;
  min-width: 120px;
  vertical-align: top;
  width: 150px;
}
.tabs-vertical-env .nav.tabs-vertical li > a {
  color: #313a46;
  text-align: center;
  white-space: nowrap;
  font-weight: 600;
  font-family: 'Hind Madurai', sans-serif;
  text-transform: uppercase;
}
.tabs-vertical-env .nav.tabs-vertical li.active > a {
  background-color: rgba(152, 166, 173, 0.1);
  border: 0;
  color: #7fc1fc;
}
.tabs-vertical-env-right .tab-content {
  padding: 0 20px 0 0;
}
.tabs-bordered {
  border-bottom: 2px solid rgba(152, 166, 173, 0.2) !important;
}
.tabs-bordered li a,
.tabs-bordered li a:hover,
.tabs-bordered li a:focus {
  border: 0 !important;
  background-color: #ffffff !important;
  padding: 10px 20px !important;
}
.tabs-bordered li.active a,
.tabs-bordered li.active a:hover,
.tabs-bordered li.active a:focus {
  border-bottom: 2px solid #7fc1fc !important;
  margin-bottom: -1px;
  color: #7fc1fc;
}
/* Navpills */
.nav-pills > li > a {
  color: #313a46;
}
.nav-pills > li.active > a,
.nav-pills > li.active > a:focus,
.nav-pills > li.active > a:hover {
  background-color: #7fc1fc;
}
/* =============
   Progressbars
============= */
.progress {
  -webkit-box-shadow: none !important;
  background-color: #f3f3f3;
  box-shadow: none !important;
  height: 10px;
  margin-bottom: 18px;
  overflow: hidden;
}
.progress-bar {
  box-shadow: none;
  font-size: 8px;
  font-weight: 600;
  line-height: 12px;
}
.progress.progress-sm {
  height: 5px !important;
}
.progress.progress-sm .progress-bar {
  font-size: 8px;
  line-height: 5px;
}
.progress.progress-md {
  height: 15px !important;
}
.progress.progress-md .progress-bar {
  font-size: 10.8px;
  line-height: 14.4px;
}
.progress.progress-lg {
  height: 20px !important;
}
.progress.progress-lg .progress-bar {
  font-size: 12px;
  line-height: 20px;
}
.progress-bar-primary {
  background-color: #188ae2;
}
.progress-bar-success {
  background-color: #4bd396;
}
.progress-bar-info {
  background-color: #3ac9d6;
}
.progress-bar-warning {
  background-color: #f9c851;
}
.progress-bar-danger {
  background-color: #f5707a;
}
.progress-bar-inverse {
  background-color: #3b3e47;
}
.progress-bar-purple {
  background-color: #6b5fb5;
}
.progress-bar-pink {
  background-color: #f06292;
}
.progress-bar-custom {
  background-color: #7fc1fc;
}
.progress-bar-orange {
  background-color: #ff9800;
}
.progress-bar-brown {
  background-color: #8d6e63;
}
.progress-bar-teal {
  background-color: #26a69a;
}
/* Progressbar Vertical */
.progress-vertical {
  min-height: 250px;
  height: 250px;
  width: 10px;
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  margin-right: 20px;
}
.progress-vertical .progress-bar {
  width: 100%;
}
.progress-vertical-bottom {
  min-height: 250px;
  height: 250px;
  position: relative;
  width: 10px;
  display: inline-block;
  margin-bottom: 0;
  margin-right: 20px;
}
.progress-vertical-bottom .progress-bar {
  width: 100%;
  position: absolute;
  bottom: 0;
}
.progress-vertical.progress-sm,
.progress-vertical-bottom.progress-sm {
  width: 5px !important;
}
.progress-vertical.progress-sm .progress-bar,
.progress-vertical-bottom.progress-sm .progress-bar {
  font-size: 8px;
  line-height: 5px;
}
.progress-vertical.progress-md,
.progress-vertical-bottom.progress-md {
  width: 15px !important;
}
.progress-vertical.progress-md .progress-bar,
.progress-vertical-bottom.progress-md .progress-bar {
  font-size: 10.8px;
  line-height: 14.4px;
}
.progress-vertical.progress-lg,
.progress-vertical-bottom.progress-lg {
  width: 20px !important;
}
.progress-vertical.progress-lg .progress-bar,
.progress-vertical-bottom.progress-lg .progress-bar {
  font-size: 12px;
  line-height: 20px;
}
/* =============
   Notification
============= */
#toast-container > div {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  opacity: 1;
}
#toast-container > :hover {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  opacity: 0.98;
}
.toast {
  background-color: #7fc1fc;
}
.toast-success {
  background-color: #4bd396;
  border: 2px solid #4bd396;
}
.toast-error {
  background-color: #f5707a;
  border: 2px solid #f5707a;
}
.toast-info {
  background-color: #3ac9d6;
  border: 2px solid #3ac9d6;
}
.toast-warning {
  background-color: #f9c851;
  border: 2px solid #f9c851;
}
/* =============
   Alerts
============= */
.alert {
  position: relative;
}
.alert .alert-link {
  font-weight: 600;
  font-family: 'Hind Madurai', sans-serif;
}
.alert-icon {
  padding-left: 50px;
}
.alert-icon i {
  position: absolute;
  left: 0;
  height: 50px;
  width: 50px;
  text-align: center;
  top: 0;
  line-height: 50px;
  font-size: 22px;
}
.alert-success {
  color: #4bd396;
  background-color: #daf6ea;
  border-color: #9de7c6;
}
.alert-success .alert-link {
  color: #2ebd7d;
}
.alert-success hr {
  border-top-color: #2ebd7d;
}
.alert-info {
  color: #3ac9d6;
  background-color: #cef2f5;
  border-color: #8ee0e8;
}
.alert-info .alert-link {
  color: #26abb7;
}
.alert-info hr {
  border-top-color: #26abb7;
}
.alert-warning {
  color: #f9c851;
  background-color: #fdefcc;
  border-color: #fcdf9b;
}
.alert-warning .alert-link {
  color: #f7b820;
}
.alert-warning hr {
  border-top-color: #f7b820;
}
.alert-danger {
  color: #f5707a;
  background-color: #fde7e9;
  border-color: #fab8bd;
}
.alert-danger .alert-link {
  color: #f2404e;
}
.alert-danger hr {
  border-top-color: #f2404e;
}
/* =============
   Carousel
============= */
.carousel-control {
  width: 10%;
}
.carousel-control span {
  position: absolute;
  top: 50%;
  z-index: 5;
  display: inline-block;
  font-size: 30px;
}
/* =========== */
/* Sweet Alert */
/* =========== */
.sweet-alert {
  background: url("../images/bg-pattern.png");
}
.sweet-alert h2 {
  font-size: 24px;
  position: relative;
}
.sweet-alert p {
  font-size: 14px;
  line-height: 22px;
}
.sweet-alert .icon.success .placeholder {
  border: 4px solid rgba(75, 211, 150, 0.3);
}
.sweet-alert .icon.success .line {
  background-color: #4bd396;
}
.sweet-alert .icon.warning {
  border-color: #f9c851;
}
.sweet-alert .icon.info {
  border-color: #3ac9d6;
}
.sweet-alert .btn-warning:focus,
.sweet-alert .btn-info:focus,
.sweet-alert .btn-success:focus,
.sweet-alert .btn-danger:focus,
.sweet-alert .btn-default:focus {
  box-shadow: none;
}
.sweet-alert .btn-lg {
  font-size: 15px !important;
  padding: 6px 14px !important;
}
/* =============
   Widgets
============= */
.widget-box-one .widget-one-icon {
  position: absolute;
  right: 30px;
  font-size: 72px !important;
  top: 0;
  color: #f3f3f3;
  overflow: hidden;
  vertical-align: middle;
  line-height: 2 !important;
}
.widget-box-one .wigdet-one-content {
  position: relative;
}
.widget-box-two .widget-two-icon {
  position: absolute;
  right: 30px;
  font-size: 42px !important;
  top: 30px;
  overflow: hidden;
  vertical-align: middle;
  height: 80px;
  width: 80px;
  text-align: center;
  line-height: 80px;
  border-radius: 50%;
}
.widget-box-two .wigdet-two-content {
  position: relative;
}
.widget-two-primary {
  background-color: rgba(24, 138, 226, 0.2);
  border-color: rgba(24, 138, 226, 0.5);
}
.widget-two-primary .widget-two-icon {
  color: rgba(24, 138, 226, 0.5);
  border: 2px solid rgba(24, 138, 226, 0.5);
}
.widget-two-success {
  background-color: rgba(75, 211, 150, 0.2);
  border-color: rgba(75, 211, 150, 0.5);
}
.widget-two-success .widget-two-icon {
  color: rgba(75, 211, 150, 0.5);
  border: 2px solid rgba(75, 211, 150, 0.5);
}
.widget-two-warning {
  background-color: rgba(249, 200, 81, 0.2);
  border-color: rgba(249, 200, 81, 0.5);
}
.widget-two-warning .widget-two-icon {
  color: rgba(249, 200, 81, 0.5);
  border: 2px solid rgba(249, 200, 81, 0.5);
}
.widget-two-info {
  background-color: rgba(58, 201, 214, 0.2);
  border-color: rgba(58, 201, 214, 0.5);
}
.widget-two-info .widget-two-icon {
  color: rgba(58, 201, 214, 0.5);
  border: 2px solid rgba(58, 201, 214, 0.5);
}
.widget-two-danger {
  background-color: rgba(245, 112, 122, 0.2);
  border-color: rgba(245, 112, 122, 0.5);
}
.widget-two-danger .widget-two-icon {
  color: rgba(245, 112, 122, 0.5);
  border: 2px solid rgba(245, 112, 122, 0.5);
}
.widget-two-inverse {
  background-color: rgba(59, 62, 71, 0.2);
  border-color: rgba(59, 62, 71, 0.5);
}
.widget-two-inverse .widget-two-icon {
  color: rgba(59, 62, 71, 0.5);
  border: 2px solid rgba(59, 62, 71, 0.5);
}
.widget-two-purple {
  background-color: rgba(107, 95, 181, 0.2);
  border-color: rgba(107, 95, 181, 0.5);
}
.widget-two-purple .widget-two-icon {
  color: rgba(107, 95, 181, 0.5);
  border: 2px solid rgba(107, 95, 181, 0.5);
}
.widget-two-pink {
  background-color: rgba(240, 98, 146, 0.2);
  border-color: rgba(240, 98, 146, 0.5);
}
.widget-two-pink .widget-two-icon {
  color: rgba(240, 98, 146, 0.5);
  border: 2px solid rgba(240, 98, 146, 0.5);
}
.widget-two-orange {
  background-color: rgba(255, 152, 0, 0.2);
  border-color: rgba(255, 152, 0, 0.5);
}
.widget-two-orange .widget-two-icon {
  color: rgba(255, 152, 0, 0.5);
  border: 2px solid rgba(255, 152, 0, 0.5);
}
.widget-two-brown {
  background-color: rgba(141, 110, 99, 0.2);
  border-color: rgba(141, 110, 99, 0.5);
}
.widget-two-brown .widget-two-icon {
  color: rgba(141, 110, 99, 0.5);
  border: 2px solid rgba(141, 110, 99, 0.5);
}
.widget-two-teal {
  background-color: rgba(38, 166, 154, 0.2);
  border-color: rgba(38, 166, 154, 0.5);
}
.widget-two-teal .widget-two-icon {
  color: rgba(38, 166, 154, 0.5);
  border: 2px solid rgba(38, 166, 154, 0.5);
}
.widget-two-default {
  background-color: rgba(243, 243, 243, 0.5);
  border-color: #e6e6e6;
}
.widget-two-default .widget-two-icon {
  color: #c0c0c0;
  border: 2px solid #c0c0c0;
}
.widget-box-three .bg-icon {
  height: 80px;
  width: 80px;
  text-align: center;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -moz-border-radius: 50%;
  background-clip: padding-box;
  border: 1px dashed #98a6ad;
  background-color: #f3f3f3;
  margin-right: 20px;
}
.widget-box-three .bg-icon i {
  line-height: 80px;
  font-size: 36px;
  color: #98a6ad;
}
.tilebox-two {
  border-left: 4px solid #f3f3f3;
  background-color: #f3f3f3;
}
.tilebox-two i {
  font-size: 54px;
  opacity: 0.3;
  margin-top: 15px;
}
.tilebox-custom {
  border-left-color: #7fc1fc;
}
.tilebox-primary {
  border-left-color: #188ae2;
}
.tilebox-success {
  border-left-color: #4bd396;
}
.tilebox-info {
  border-left-color: #3ac9d6;
}
.tilebox-warning {
  border-left-color: #f9c851;
}
.tilebox-danger {
  border-left-color: #f5707a;
}
.tilebox-inverse {
  border-left-color: #3b3e47;
}
.tilebox-pink {
  border-left-color: #f06292;
}
.tilebox-purple {
  border-left-color: #6b5fb5;
}
.tilebox-dark {
  border-left-color: #313a46;
}
.tilebox-brown {
  border-left-color: #8d6e63;
}
.tilebox-orange {
  border-left-color: #ff9800;
}
.tilebox-teal {
  border-left-color: #26a69a;
}
/* Inbox-widget */
.inbox-widget .inbox-item {
  border-bottom: 1px solid rgba(243, 243, 243, 0.9);
  overflow: hidden;
  padding: 10px 0;
  position: relative;
}
.inbox-widget .inbox-item .inbox-item-img {
  display: block;
  float: left;
  margin-right: 15px;
  width: 40px;
}
.inbox-widget .inbox-item img {
  width: 40px;
}
.inbox-widget .inbox-item .inbox-item-author {
  color: #313a46;
  display: block;
  margin: 0;
}
.inbox-widget .inbox-item .inbox-item-text {
  color: #a0a0a0;
  display: block;
  font-size: 12px;
  margin: 0;
}
.inbox-widget .inbox-item .inbox-item-date {
  color: #a9a9a9;
  font-size: 11px;
  position: absolute;
  right: 7px;
  top: 2px;
}
/* Chat widget */
.conversation-list {
  list-style: none;
  padding: 0 10px;
}
.conversation-list li {
  margin-bottom: 24px;
}
.conversation-list .chat-avatar {
  display: inline-block;
  float: left;
  text-align: center;
  width: 42px;
}
.conversation-list .chat-avatar img {
  border-radius: 100%;
  width: 100%;
}
.conversation-list .chat-avatar i {
  font-size: 12px;
  font-style: normal;
}
.conversation-list .ctext-wrap {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  background: rgba(243, 243, 243, 0.6);
  border-radius: 3px;
  display: inline-block;
  padding: 12px;
  position: relative;
}
.conversation-list .ctext-wrap i {
  color: #313a46;
  display: block;
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  font-family: 'Hind Madurai', sans-serif;
  position: relative;
}
.conversation-list .ctext-wrap p {
  margin: 0;
  padding-top: 3px;
}
.conversation-list .ctext-wrap:after {
  right: 100%;
  top: 0;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-top-color: rgba(243, 243, 243, 0.6);
  border-width: 8px;
  margin-left: -1px;
  border-right-color: rgba(243, 243, 243, 0.6);
}
.conversation-list .conversation-text {
  display: inline-block;
  float: left;
  font-size: 12px;
  margin-left: 12px;
  width: 70%;
}
.conversation-list .odd .chat-avatar {
  float: right !important;
}
.conversation-list .odd .conversation-text {
  float: right !important;
  margin-right: 12px;
  text-align: right;
  width: 70% !important;
}
.conversation-list .odd .ctext-wrap {
  background-color: #f5707a;
  color: #ffffff;
}
.conversation-list .odd .ctext-wrap i {
  color: #ffffff;
}
.conversation-list .odd .ctext-wrap:after {
  border-color: rgba(238, 238, 242, 0) !important;
  border-left-color: #f5707a !important;
  border-top-color: #f5707a !important;
  left: 100% !important;
  margin-right: -1px;
}
.chat-send {
  padding-left: 0;
  padding-right: 30px;
}
.chat-send button {
  width: 100%;
}
.chat-inputbar {
  padding-left: 30px;
}
/* Todos widget */
#todo-message {
  font-size: 16px;
}
.todo-list li {
  border-radius: 0;
  border: 0;
  margin: 0;
  padding: 1px;
  color: #98a6ad;
}
.todo-list li:last-of-type {
  border-bottom: none;
}
.todo-list label {
  font-family: 'Varela Round', sans-serif;
}
.todo-send {
  padding-left: 0;
}
/* Avatar box */
.avatar-sm-box {
  height: 32px;
  width: 32px;
  color: #ffffff;
  display: block;
  line-height: 32px;
  text-align: center;
  border-radius: 50%;
  font-family: 'Hind Madurai', sans-serif;
  font-size: 16px;
}
/* =============
   Nestable
============= */
.custom-dd .dd-list .dd-item .dd-handle {
  background: #f3f3f3;
  border: none;
  padding: 8px 16px;
  height: auto;
  font-weight: 600;
  font-family: 'Hind Madurai', sans-serif;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  background-clip: padding-box;
}
.custom-dd .dd-list .dd-item .dd-handle:hover {
  color: #7fc1fc;
}
.custom-dd .dd-list .dd-item button {
  height: auto;
  font-size: 17px;
  margin: 8px auto;
  color: #797979;
  width: 30px;
}
.custom-dd-empty .dd-list .dd3-handle {
  border: none;
  background: #f3f3f3;
  height: 36px !important;
  width: 36px !important;
}
.custom-dd-empty .dd-list .dd3-handle:before {
  color: inherit;
  top: 7px;
}
.custom-dd-empty .dd-list .dd3-handle:hover {
  color: #7fc1fc;
}
.custom-dd-empty .dd-list .dd3-content {
  height: auto;
  border: none;
  padding: 8px 16px 8px 46px;
  background: #f3f3f3;
  font-weight: 600;
  font-family: 'Hind Madurai', sans-serif;
}
.custom-dd-empty .dd-list .dd3-content:hover {
  color: #7fc1fc;
}
.custom-dd-empty .dd-list button {
  width: 26px;
  height: 26px;
  font-size: 16px;
  font-weight: 600;
}
.dd-dragel > .dd-item .dd-handle {
  padding: 8px 16px !important;
  background: #f3f3f3;
  height: auto;
}
.dd-placeholder,
.dd-empty {
  background: #f3f3f3;
  border: 1px dashed #98a6ad;
}
.dd-dragel > .dd3-item > .dd3-handle {
  border: none;
  background: #f3f3f3;
  height: 36px !important;
  width: 36px !important;
}
.dd-dragel > .dd3-item > .dd3-handle:before {
  color: inherit;
  top: 7px;
}
.dd-dragel > .dd3-item > .dd3-content {
  padding: 8px 16px 8px 46px;
  background: #f3f3f3;
  height: auto;
}
/* =============
   Rating
============= */
.rating-md i {
  font-size: 18px;
}
.rating-lg i {
  font-size: 24px;
}
/* =============
   Calendar
============= */
.calendar {
  float: left;
  margin-bottom: 0;
}
.fc-view {
  margin-top: 30px;
}
.none-border .modal-footer {
  border-top: none;
}
.fc-toolbar {
  margin-bottom: 5px;
  margin-top: 15px;
}
.fc-toolbar h2 {
  font-size: 18px;
  font-weight: 600;
  font-family: 'Hind Madurai', sans-serif;
  line-height: 30px;
  text-transform: uppercase;
}
.fc-day-grid-event .fc-time {
  font-family: 'Hind Madurai', sans-serif;
}
.fc-day {
  background: #ffffff;
}
.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar button:focus,
.fc-toolbar button:hover,
.fc-toolbar .ui-state-hover {
  z-index: 0;
}
.fc th.fc-widget-header {
  background: #eeeeee;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 0;
  text-transform: uppercase;
}
.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-popover {
  border-color: #f3f3f3;
}
.fc-button {
  background: #f1f1f1;
  border: none;
  color: #797979;
  text-transform: capitalize;
  box-shadow: none !important;
  border-radius: 3px !important;
  margin: 0 3px !important;
  padding: 6px 12px !important;
  height: auto !important;
}
.fc-text-arrow {
  font-family: inherit;
  font-size: 16px;
}
.fc-state-hover {
  background: #f3f3f3;
}
.fc-state-highlight {
  background: #f0f0f0;
}
.fc-state-down,
.fc-state-active,
.fc-state-disabled {
  background-color: #7fc1fc !important;
  color: #ffffff !important;
  text-shadow: none !important;
}
.fc-cell-overlay {
  background: #f0f0f0;
}
.fc-unthemed .fc-today {
  background: #ffffff;
}
.fc-event {
  border-radius: 2px;
  border: none;
  cursor: move;
  font-size: 13px;
  margin: 5px 7px;
  padding: 5px 5px;
  text-align: center;
}
.external-event {
  cursor: move;
  margin: 10px 0;
  padding: 6px 10px;
}
.external-event.bg-primary {
  background-color: rgba(24, 138, 226, 0.3) !important;
  color: #188ae2;
}
.external-event.bg-success {
  background-color: rgba(75, 211, 150, 0.3) !important;
  color: #4bd396;
}
.external-event.bg-info {
  background-color: rgba(58, 201, 214, 0.3) !important;
  color: #3ac9d6;
}
.external-event.bg-warning {
  background-color: rgba(249, 200, 81, 0.3) !important;
  color: #f9c851;
}
.external-event.bg-danger {
  background-color: rgba(245, 112, 122, 0.3) !important;
  color: #f5707a;
}
.external-event.bg-pink {
  background-color: rgba(240, 98, 146, 0.3) !important;
  color: #f06292;
}
.external-event.bg-purple {
  background-color: rgba(107, 95, 181, 0.3) !important;
  color: #6b5fb5;
}
.external-event.bg-inverse {
  background-color: rgba(59, 62, 71, 0.3) !important;
  color: #3b3e47;
}
.external-event.bg-orange {
  background-color: rgba(255, 152, 0, 0.3) !important;
  color: #ff9800;
}
.external-event.bg-brown {
  background-color: rgba(141, 110, 99, 0.3) !important;
  color: #8d6e63;
}
.external-event.bg-teal {
  background-color: rgba(38, 166, 154, 0.3) !important;
  color: #26a69a;
}
.fc-basic-view td.fc-week-number span {
  padding-right: 8px;
  font-weight: 700;
  font-family: 'Hind Madurai', sans-serif;
}
.fc-basic-view td.fc-day-number {
  padding-right: 8px;
  font-weight: 700;
  font-family: 'Hind Madurai', sans-serif;
}
/* =============
   Form
============= */
/* Form components */
label {
  font-weight: 600;
  font-family: 'Hind Madurai', sans-serif;
}
textarea.form-control {
  min-height: 90px;
}
.form-control {
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  padding: 7px 12px;
  height: 38px;
  max-width: 100%;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  transition: all 300ms linear;
}
.form-control:focus {
  border: 1px solid #aaaaaa;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0 !important;
}
.input-lg {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px;
}
.input-sm {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}
.form-horizontal .form-group {
  margin-left: -10px;
  margin-right: -10px;
}
.form-control-feedback {
  line-height: 38px !important;
}
.input-group-btn .btn {
  padding: 8px 12px;
}
.input-group-btn .btn-sm {
  padding: 5px 10px;
}
.input-group-btn .btn-lg {
  padding: 10px 17px;
}
.has-success .checkbox,
.has-success .checkbox-inline,
.has-success .control-label,
.has-success .help-block,
.has-success .radio,
.has-success .radio-inline,
.has-success.checkbox label,
.has-success.checkbox-inline label,
.has-success.radio label,
.has-success.radio-inline label,
.has-success .form-control-feedback {
  color: #4bd396;
}
.has-warning .checkbox,
.has-warning .checkbox-inline,
.has-warning .control-label,
.has-warning .help-block,
.has-warning .radio,
.has-warning .radio-inline,
.has-warning.checkbox label,
.has-warning.checkbox-inline label,
.has-warning.radio label,
.has-warning.radio-inline label,
.has-warning .form-control-feedback {
  color: #f9c851;
}
.has-error .checkbox,
.has-error .checkbox-inline,
.has-error .control-label,
.has-error .help-block,
.has-error .radio,
.has-error .radio-inline,
.has-error.checkbox label,
.has-error.checkbox-inline label,
.has-error.radio label,
.has-error.radio-inline label,
.has-error .form-control-feedback {
  color: #f5707a;
}
.has-success .form-control {
  border-color: #4bd396;
  box-shadow: none !important;
}
.has-warning .form-control {
  border-color: #f9c851;
  box-shadow: none !important;
}
.has-error .form-control {
  border-color: #f5707a;
  box-shadow: none !important;
}
.input-group-addon {
  border-radius: 2px;
  border: 1px solid #eeeeee;
}
/* == Form Advanced */
/* Bootstrap tagsinput */
.bootstrap-tagsinput {
  box-shadow: none;
  padding: 5px 7px 5px;
  width: 100%;
  border: 1px solid #e3e3e3;
}
.bootstrap-tagsinput .label-info {
  background-color: #7fc1fc !important;
  display: inline-block;
  padding: 5px;
  margin: 3px 1px;
}
/* CSS Switch */
input[switch] {
  display: none;
}
input[switch] + label {
  font-size: 1em;
  line-height: 1;
  width: 56px;
  height: 24px;
  background-color: #ddd;
  background-image: none;
  border-radius: 2rem;
  padding: 0.16667rem;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  position: relative;
  font-family: inherit;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
input[switch] + label:before {
  color: #313a46;
  content: attr(data-off-label);
  display: block;
  font-family: inherit;
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
  position: absolute;
  right: 6px;
  margin: 0.21667rem;
  top: 0;
  text-align: center;
  min-width: 1.66667rem;
  overflow: hidden;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
input[switch] + label:after {
  content: '';
  position: absolute;
  left: 3px;
  background-color: #f7f7f7;
  box-shadow: none;
  border-radius: 2rem;
  height: 20px;
  width: 20px;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
input[switch]:checked + label {
  background-color: #7fc1fc;
}
input[switch]:checked + label:before {
  color: #ffffff;
  content: attr(data-on-label);
  right: auto;
  left: 6px;
}
input[switch]:checked + label:after {
  left: 33px;
  background-color: #f7f7f7;
}
input[switch="bool"] + label {
  background-color: #f5707a;
}
input[switch="bool"] + label:before,
input[switch="bool"]:checked + label:before,
input[switch="default"]:checked + label:before {
  color: #ffffff !important;
}
input[switch="bool"]:checked + label {
  background-color: #4bd396;
}
input[switch="default"]:checked + label {
  background-color: #a2a2a2;
}
input[switch="primary"]:checked + label {
  background-color: #188ae2;
}
input[switch="success"]:checked + label {
  background-color: #4bd396;
}
input[switch="info"]:checked + label {
  background-color: #3ac9d6;
}
input[switch="warning"]:checked + label {
  background-color: #f9c851;
}
input[switch="inverse"]:checked + label {
  background-color: #3b3e47;
}
input[switch="pink"]:checked + label {
  background-color: #f06292;
}
input[switch="purple"]:checked + label {
  background-color: #6b5fb5;
}
input[switch="orange"]:checked + label {
  background-color: #ff9800;
}
input[switch="brown"]:checked + label {
  background-color: #8d6e63;
}
input[switch="teal"]:checked + label {
  background-color: #26a69a;
}
/* Button Switch css */
.btn-switch {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.btn-switch > input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
  left: -100%;
  top: -100%;
}
.btn-switch > input[type="checkbox"] + .btn {
  background-color: transparent !important;
  border-color: #7fc1fc;
  color: #7fc1fc !important;
}
.btn-switch > input[type="checkbox"] + .btn > em {
  display: inline-block;
  border: 1px solid #7fc1fc;
  border-radius: 50%;
  padding: 2px;
  margin: 0 4px 0 0;
  top: 1px;
  font-size: 10px;
  text-align: center;
}
.btn-switch > input[type="checkbox"] + .btn > em:before {
  opacity: 0;
}
.btn-switch > input[type="checkbox"]:checked + .btn {
  background-color: #7fc1fc !important;
  color: #ffffff !important;
}
.btn-switch > input[type="checkbox"]:checked + .btn > em {
  background-color: #ffffff;
  color: #7fc1fc !important;
}
.btn-switch > input[type="checkbox"]:checked + .btn > em:before {
  opacity: 1;
}
.btn-switch-primary > input[type="checkbox"] + .btn,
.btn-switch-primary > input[type="checkbox"]:checked + .btn > em {
  color: #188ae2 !important;
}
.btn-switch-primary > input[type="checkbox"] + .btn,
.btn-switch-primary > input[type="checkbox"] + .btn > em {
  border-color: #188ae2 !important;
}
.btn-switch-primary > input[type="checkbox"]:checked + .btn {
  background-color: #188ae2 !important;
}
.btn-switch-success > input[type="checkbox"] + .btn,
.btn-switch-success > input[type="checkbox"]:checked + .btn > em {
  color: #4bd396 !important;
}
.btn-switch-success > input[type="checkbox"] + .btn,
.btn-switch-success > input[type="checkbox"] + .btn > em {
  border-color: #4bd396 !important;
}
.btn-switch-success > input[type="checkbox"]:checked + .btn {
  background-color: #4bd396 !important;
}
.btn-switch-info > input[type="checkbox"] + .btn,
.btn-switch-info > input[type="checkbox"]:checked + .btn > em {
  color: #3ac9d6 !important;
}
.btn-switch-info > input[type="checkbox"] + .btn,
.btn-switch-info > input[type="checkbox"] + .btn > em {
  border-color: #3ac9d6 !important;
}
.btn-switch-info > input[type="checkbox"]:checked + .btn {
  background-color: #3ac9d6 !important;
}
.btn-switch-warning > input[type="checkbox"] + .btn,
.btn-switch-warning > input[type="checkbox"]:checked + .btn > em {
  color: #f9c851 !important;
}
.btn-switch-warning > input[type="checkbox"] + .btn,
.btn-switch-warning > input[type="checkbox"] + .btn > em {
  border-color: #f9c851 !important;
}
.btn-switch-warning > input[type="checkbox"]:checked + .btn {
  background-color: #f9c851 !important;
}
.btn-switch-danger > input[type="checkbox"] + .btn,
.btn-switch-danger > input[type="checkbox"]:checked + .btn > em {
  color: #f5707a !important;
}
.btn-switch-danger > input[type="checkbox"] + .btn,
.btn-switch-danger > input[type="checkbox"] + .btn > em {
  border-color: #f5707a !important;
}
.btn-switch-danger > input[type="checkbox"]:checked + .btn {
  background-color: #f5707a !important;
}
.btn-switch-inverse > input[type="checkbox"] + .btn,
.btn-switch-inverse > input[type="checkbox"]:checked + .btn > em {
  color: #3b3e47 !important;
}
.btn-switch-inverse > input[type="checkbox"] + .btn,
.btn-switch-inverse > input[type="checkbox"] + .btn > em {
  border-color: #3b3e47 !important;
}
.btn-switch-inverse > input[type="checkbox"]:checked + .btn {
  background-color: #3b3e47 !important;
}
.btn-switch-pink > input[type="checkbox"] + .btn,
.btn-switch-pink > input[type="checkbox"]:checked + .btn > em {
  color: #f06292 !important;
}
.btn-switch-pink > input[type="checkbox"] + .btn,
.btn-switch-pink > input[type="checkbox"] + .btn > em {
  border-color: #f06292 !important;
}
.btn-switch-pink > input[type="checkbox"]:checked + .btn {
  background-color: #f06292 !important;
}
.btn-switch-purple > input[type="checkbox"] + .btn,
.btn-switch-purple > input[type="checkbox"]:checked + .btn > em {
  color: #6b5fb5 !important;
}
.btn-switch-purple > input[type="checkbox"] + .btn,
.btn-switch-purple > input[type="checkbox"] + .btn > em {
  border-color: #6b5fb5 !important;
}
.btn-switch-purple > input[type="checkbox"]:checked + .btn {
  background-color: #6b5fb5 !important;
}
.btn-switch-orange > input[type="checkbox"] + .btn,
.btn-switch-orange > input[type="checkbox"]:checked + .btn > em {
  color: #ff9800 !important;
}
.btn-switch-orange > input[type="checkbox"] + .btn,
.btn-switch-orange > input[type="checkbox"] + .btn > em {
  border-color: #ff9800 !important;
}
.btn-switch-orange > input[type="checkbox"]:checked + .btn {
  background-color: #ff9800 !important;
}
.btn-switch-brown > input[type="checkbox"] + .btn,
.btn-switch-brown > input[type="checkbox"]:checked + .btn > em {
  color: #8d6e63 !important;
}
.btn-switch-brown > input[type="checkbox"] + .btn,
.btn-switch-brown > input[type="checkbox"] + .btn > em {
  border-color: #8d6e63 !important;
}
.btn-switch-brown > input[type="checkbox"]:checked + .btn {
  background-color: #8d6e63 !important;
}
.btn-switch-teal > input[type="checkbox"] + .btn,
.btn-switch-teal > input[type="checkbox"]:checked + .btn > em {
  color: #26a69a !important;
}
.btn-switch-teal > input[type="checkbox"] + .btn,
.btn-switch-teal > input[type="checkbox"] + .btn > em {
  border-color: #26a69a !important;
}
.btn-switch-teal > input[type="checkbox"]:checked + .btn {
  background-color: #26a69a !important;
}
/* Multiple select */
.search-input {
  margin-bottom: 10px;
}
.ms-container {
  background: transparent url('../plugins/multiselect/img/multiple-arrow.png') no-repeat 50% 50%;
  width: 100% !important;
  max-width: 360px !important;
}
.ms-container .ms-list {
  box-shadow: none;
  border: 1px solid rgba(49, 58, 70, 0.2);
}
.ms-container .ms-list.ms-focus {
  box-shadow: none;
  border: 1px solid rgba(49, 58, 70, 0.4);
}
.ms-container .ms-selectable {
  box-shadow: none;
  outline: none !important;
}
.ms-container .ms-selectable li.ms-elem-selectable {
  border: none;
  padding: 5px 10px;
}
.ms-container .ms-selectable li.ms-hover {
  background-color: #7fc1fc;
}
.ms-container .ms-selection li.ms-elem-selection {
  border: none;
  padding: 5px 10px;
}
.ms-container .ms-selection li.ms-hover {
  background-color: #7fc1fc;
}
/* Select 2 */
.select2-container {
  width: 100% !important;
}
.select2-container .select2-selection--single {
  border: 1px solid #E3E3E3 !important;
  height: 38px !important;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  line-height: 36px !important;
  padding-left: 12px !important;
}
.select2-container .select2-selection--single .select2-selection__arrow {
  height: 34px;
  width: 34px;
  right: 3px;
}
.select2-container .select2-selection--single .select2-selection__arrow b {
  border-color: #999 transparent transparent transparent;
  border-width: 6px 6px 0 6px;
}
.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #999 transparent !important;
  border-width: 0 6px 6px 6px !important;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #7fc1fc;
}
.select2-results__option {
  padding: 6px 12px;
}
.select2-dropdown {
  border: 1px solid #e3e3e3 !important;
  padding-top: 5px;
  -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
}
.select2-search input {
  border: 1px solid #e3e3e3 !important;
}
.select2-container .select2-selection--multiple {
  min-height: 38px !important;
  border: 1px solid #e3e3e3 !important;
}
.select2-container .select2-selection--multiple .select2-selection__rendered {
  padding: 2px 10px;
}
.select2-container .select2-selection--multiple .select2-search__field {
  margin-top: 7px;
  border: 0 !important;
}
.select2-container .select2-selection--multiple .select2-selection__choice {
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 1px;
  padding: 0 7px;
}
/* AUTOCOMPLETE */
.autocomplete-suggestions {
  border: 1px solid #f9f9f9;
  background: #ffffff;
  cursor: default;
  overflow: auto;
  -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
}
.autocomplete-suggestion {
  padding: 2px 5px;
  white-space: nowrap;
  overflow: hidden;
}
.autocomplete-no-suggestion {
  padding: 2px 5px;
}
.autocomplete-selected {
  background: rgba(152, 166, 173, 0.3);
  cursor: pointer;
}
.autocomplete-suggestions strong {
  font-weight: bold;
  color: #313a46;
}
.autocomplete-group {
  padding: 2px 5px;
}
.autocomplete-group strong {
  font-weight: bold;
  font-size: 16px;
  color: #313a46;
  display: block;
}
/* Bootstrap-select */
.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
  width: 100% !important;
}
.bootstrap-select .dropdown-toggle:focus {
  outline: none !important;
}
.bootstrap-select .glyphicon {
  padding-right: 6px;
}
/* Bootstrap filestyle */
.icon-span-filestyle {
  padding-right: 5px;
}
/* Bootstrap-touchSpin */
.bootstrap-touchspin .input-group-btn-vertical .btn {
  padding: 9px 12px;
}
.bootstrap-touchspin .input-group-btn-vertical i {
  top: 4px;
  left: 8px;
}
/* Form validation */
.parsley-error {
  border-color: #f5707a !important;
}
.parsley-errors-list {
  display: none;
  margin: 0;
  padding: 0;
}
.parsley-errors-list.filled {
  display: block;
}
.parsley-errors-list > li {
  font-size: 12px;
  list-style: none;
  color: #f5707a;
  margin-top: 5px;
}
.bootstrap-timepicker-widget table td input {
  border: 1px solid rgba(49, 58, 70, 0.3);
  width: 35px;
}
.datepicker-dropdown {
  padding: 10px !important;
}
.datepicker td,
.datepicker th {
  width: 30px;
  height: 30px;
}
.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active:hover.disabled,
.datepicker table tr td.active.disabled.disabled,
.datepicker table tr td.active.disabled:hover.disabled,
.datepicker table tr td.active[disabled],
.datepicker table tr td.active:hover[disabled],
.datepicker table tr td.active.disabled[disabled],
.datepicker table tr td.active.disabled:hover[disabled],
.datepicker table tr td.selected,
.datepicker table tr td.selected:hover,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected.disabled:hover {
  background-color: #7fc1fc !important;
  color: #ffffff !important;
  background-image: none !important;
  text-shadow: none !important;
}
.datepicker table tr td.today,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover {
  background-color: #4bd396 !important;
  color: #ffffff !important;
  background-image: none !important;
}
.datepicker-inline {
  border: 2px solid rgba(49, 58, 70, 0.1);
}
.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #7fc1fc;
}
.daterangepicker .input-mini.active {
  border: 1px solid rgba(49, 58, 70, 0.3);
}
.daterangepicker .ranges li {
  border-radius: 2px;
  color: #313a46;
  font-weight: 600;
  font-size: 12px;
  font-family: 'Hind Madurai', sans-serif;
}
.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect,
.daterangepicker select.ampmselect {
  border: 1px solid rgba(49, 58, 70, 0.3);
  padding: 2px;
  width: 60px;
}
.daterangepicker .ranges li.active,
.daterangepicker .ranges li:hover {
  background-color: #7fc1fc;
  border: 1px solid #7fc1fc;
  color: #ffffff;
}
/* Wizard */
.wizard > .content {
  background: #ffffff;
  min-height: 240px;
  padding: 20px !important;
  border: 1px solid #f3f3f3;
  margin-top: 10px !important;
}
.wizard > .content > .body {
  padding: 0px;
  position: relative;
  width: 100%;
}
.wizard > .content > .body input {
  border: 1px solid #e9e9e9;
}
.wizard > .content > .body ul > li {
  display: block;
  line-height: 30px;
}
.wizard > .content > .body label.error {
  color: #f5707a;
  margin-left: 0;
  margin-top: 5px;
  font-size: 12px;
}
.wizard > .content > .body label {
  display: inline-block;
  margin-top: 10px;
}
.wizard > .steps a {
  font-size: 16px;
  text-align: center;
  font-family: 'Hind Madurai', sans-serif;
}
.wizard > .steps .number {
  font-size: 86px;
  line-height: 86px;
  font-family: 'Hind Madurai', sans-serif;
  position: absolute;
  left: 10px;
  top: -14px;
  opacity: 0.1;
}
.wizard > .steps .disabled a {
  background: #f3f3f3;
  color: #3c4655 !important;
  cursor: default;
  border: 1px solid #e6e6e6;
}
.wizard > .steps .disabled a:hover {
  background: #e6e6e6;
  border: 1px solid #dadada;
}
.wizard > .steps .disabled a:active {
  background: #e6e6e6;
  border: 1px solid #dadada;
}
.wizard > .steps .current a {
  background: #7fc1fc;
}
.wizard > .steps .current a:hover {
  background: #7fc1fc;
}
.wizard > .steps .current a:hover .number {
  color: #ffffff;
  opacity: 0.4;
}
.wizard > .steps .current a:active {
  background: #7fc1fc;
}
.wizard > .steps .current a:active .number {
  color: #ffffff;
  opacity: 0.4;
}
.wizard > .steps .current a .number {
  color: #ffffff;
  opacity: 0.4;
}
.wizard > .steps .done a {
  background: #f3f3f3 !important;
  color: #3c4655 !important;
}
.wizard > .steps a,
.wizard > .steps a:hover,
.wizard > .steps a:active,
.wizard > .content {
  border-radius: 2px;
  position: relative;
}
.wizard > .actions {
  margin-top: 15px !important;
}
.wizard > .actions a {
  background: #7fc1fc;
  border-radius: 2px !important;
  color: #ffffff;
}
.wizard > .actions a:hover {
  background: #349dfa;
  color: #ffffff;
}
.wizard > .actions a:active {
  background: #349dfa;
  color: #ffffff;
}
.wizard > .actions .disabled a {
  background: #ffffff;
  color: #313a46;
  cursor: default;
  border: 1px solid #eaeaea;
}
.wizard > .actions .disabled a:hover {
  background: #f3f3f3;
  color: #313a46;
  cursor: default;
  border: 1px solid #eaeaea;
}
.wizard > .actions .disabled a:active {
  background: #f3f3f3;
  color: #313a46;
  cursor: default;
  border: 1px solid #eaeaea;
}
.wizard.vertical > .content {
  margin-top: 0px !important;
}
@media (max-width: 560px) {
  .wizard.vertical > .steps,
  .wizard.vertical > .content {
    width: 100%;
  }
}
/* Summernote */
.note-editor {
  position: relative;
}
.note-editor .btn-default {
  background-color: transparent;
  border-color: transparent !important;
}
.note-editor .btn-group-sm > .btn,
.note-editor .btn-sm {
  padding: 8px 12px !important;
}
.note-editor .note-toolbar {
  background-color: #f3f3f3;
  border-bottom: 1px solid #eeeeee;
  margin: 0;
}
.note-editor .note-statusbar {
  background-color: #ffffff;
}
.note-editor .note-statusbar .note-resizebar {
  border-top: none;
  height: 15px;
  padding-top: 3px;
}
.note-editor.note-frame {
  border: 1px solid #eeeeee !important;
}
.note-popover .popover .popover-content {
  padding: 5px 0 10px 5px;
}
.note-popover .btn-default {
  background-color: transparent;
  border-color: transparent !important;
}
.note-popover .btn-group-sm > .btn,
.note-popover .btn-sm {
  padding: 8px 12px !important;
}
.note-toolbar {
  padding: 5px 0 10px 5px;
}
/* Wysiwig css */
.mce-panel {
  border: 1px solid #f3f3f3 !important;
  background-color: #f3f3f3 !important;
}
.mce-menu {
  background-color: #ffffff !important;
  box-shadow: 0 0px 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);
}
.mce-menubar .mce-menubtn:hover,
.mce-menubar .mce-menubtn.mce-active,
.mce-menubar .mce-menubtn:focus {
  border-color: #f3f3f3 !important;
}
.mce-menu-item:hover,
.mce-menu-item.mce-selected,
.mce-menu-item:focus,
.mce-menu-item-normal.mce-active,
.mce-primary {
  background-color: #7fc1fc !important;
}
.mce-window-head .mce-title {
  font-family: 'Hind Madurai', sans-serif;
}
.mce-window {
  padding: 20px !important;
}
.mce-window-head {
  padding-bottom: 20px !important;
  border-bottom: 0 !important;
}
/* == Form Uploads == */
.jFiler-input-dragDrop {
  width: 100%;
  background-color: #fafafa;
}
.jFiler-theme-default .jFiler-input {
  width: 100%;
}
.jFiler-theme-default .jFiler-input-button {
  background-color: #7fc1fc;
  background-image: none !important;
  color: #ffffff;
  border: 1px solid #7fc1fc !important;
}
/* =============
   Tables
============= */
th {
  font-family: 'Hind Madurai', sans-serif;
  font-weight: 600;
}
.table-centered td {
  vertical-align: middle !important;
}
/* Data table */
table.dataTable {
  margin-top: 10px !important;
  margin-bottom: 18px !important;
}
.table-bordered.dataTable > thead > tr > td,
.table-bordered.dataTable > thead > tr > th {
  border-bottom-width: 1px !important;
}
/* Fixed Header table */
.fixedHeader-floating {
  top: 70px !important;
}
/* Key Table border */
table.dataTable th.focus,
table.dataTable td.focus {
  outline: 2px solid #7fc1fc !important;
  outline-offset: -1px;
  background-color: #7fc1fc;
  color: #ffffff;
}
/* Responsive data table */
table.dataTable.dtr-inline.collapsed > tbody > tr > td:first-child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > th:first-child:before {
  box-shadow: 0 0 3px rgba(67, 89, 102, 0.2);
  background-color: #4bd396;
}
table.dataTable.dtr-inline.collapsed > tbody > tr.parent > td:first-child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr.parent > th:first-child:before {
  background-color: #f5707a;
}
table.dataTable > tbody > tr.child span.dtr-title {
  font-family: 'Hind Madurai', sans-serif;
}
/* ColVid Tables */
div.ColVis {
  float: none;
  margin-right: 30px;
}
button.ColVis_Button,
.ColVis_Button:hover {
  float: none;
  border-radius: 3px;
  outline: none !important;
  background: none;
  box-shadow: none;
  color: #ffffff !important;
  background-color: #7fc1fc !important;
  border: 1px solid #7fc1fc !important;
}
.dataTables_length {
  float: left;
}
div.ColVis_collectionBackground {
  background-color: transparent;
}
ul.ColVis_collection {
  padding: 10px 0 0 0;
  background-color: #ffffff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  border: none;
}
ul.ColVis_collection li {
  background: transparent !important;
  padding: 3px 10px !important;
  border: none !important;
  box-shadow: none !important;
}
#datatable-colvid_info {
  float: left;
}
/* Responsive Table */
.table-rep-plugin .dropdown-menu li.checkbox-row {
  padding: 2px 15px !important;
}
.table-rep-plugin .table-responsive {
  border: none !important;
}
.table-rep-plugin tbody th {
  font-size: 14px;
  font-weight: normal;
}
.table-rep-plugin .checkbox-row {
  padding-left: 40px;
}
.table-rep-plugin .checkbox-row label {
  display: inline-block;
  padding-left: 5px;
  position: relative;
}
.table-rep-plugin .checkbox-row label::before {
  -o-transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  background-color: #ffffff;
  border-radius: 3px;
  border: 1px solid #98a6ad;
  content: "";
  display: inline-block;
  height: 17px;
  left: 0;
  margin-left: -20px;
  position: absolute;
  transition: 0.3s ease-in-out;
  width: 17px;
  outline: none !important;
}
.table-rep-plugin .checkbox-row label::after {
  color: #f3f3f3;
  display: inline-block;
  font-size: 11px;
  height: 16px;
  left: 0;
  margin-left: -20px;
  padding-left: 3px;
  padding-top: 1px;
  position: absolute;
  top: -1px;
  width: 16px;
}
.table-rep-plugin .checkbox-row input[type="checkbox"] {
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  outline: none !important;
}
.table-rep-plugin .checkbox-row input[type="checkbox"]:disabled + label {
  opacity: 0.65;
}
.table-rep-plugin .checkbox-row input[type="checkbox"]:focus + label::before {
  outline-offset: -2px;
  outline: none;
}
.table-rep-plugin .checkbox-row input[type="checkbox"]:checked + label::after {
  content: "\f00c";
  font-family: 'FontAwesome';
}
.table-rep-plugin .checkbox-row input[type="checkbox"]:disabled + label::before {
  background-color: #eeeeee;
  cursor: not-allowed;
}
.table-rep-plugin .checkbox-row input[type="checkbox"]:checked + label::before {
  background-color: #ffffff;
  border-color: #4bd396;
}
.table-rep-plugin .checkbox-row input[type="checkbox"]:checked + label::after {
  color: #4bd396;
}
.table-rep-plugin table.focus-on tbody tr.focused th,
.table-rep-plugin table.focus-on tbody tr.focused td,
.table-rep-plugin .sticky-table-header {
  background-color: #4bd396;
  color: #ffffff;
  border-color: #4bd396;
}
.table-rep-plugin .sticky-table-header.fixed-solution {
  top: 70px !important;
}
/* Data table editable */
.add-edit-table td,
.add-edit-table th {
  vertical-align: middle !important;
}
.add-edit-table td {
  border: 0 !important;
}
#datatable-editable .actions a {
  padding: 5px;
}
#datatable-editable .form-control {
  background-color: #ffffff;
  width: 100%;
}
#datatable-editable .fa-trash-o {
  color: #f05050;
}
#datatable-editable .fa-times {
  color: #f05050;
}
#datatable-editable .fa-pencil {
  color: #29b6f6;
}
#datatable-editable .fa-save {
  color: #33b86c;
}
#datatable td {
  font-weight: normal;
}
.modal-block {
  background: transparent;
  margin: 40px auto;
  max-width: 600px;
  padding: 0;
  position: relative;
  text-align: left;
}
.dt-buttons {
  float: left;
}
div#datatable-buttons_info {
  float: left;
}
table.dataTable thead th {
  position: relative;
  background-image: none !important;
}
table.dataTable thead th.sorting:after,
table.dataTable thead th.sorting_asc:after,
table.dataTable thead th.sorting_desc:after {
  position: absolute;
  top: 7px;
  right: 8px;
  display: block;
  font-family: FontAwesome;
}
table.dataTable thead th.sorting:after {
  content: "\f0dc";
  padding-top: 0.12em;
}
table.dataTable thead th.sorting_asc:after {
  content: "\f0de";
}
table.dataTable thead th.sorting_desc:after {
  content: "\f0dd";
}
.DTFC_LeftBodyWrapper table thead th.sorting:after,
.dataTables_scrollBody table thead th.sorting:after,
.DTFC_RightBodyLiner table thead th.sorting:after,
.DTFC_LeftBodyWrapper table thead th.sorting_asc:after,
.dataTables_scrollBody table thead th.sorting_asc:after,
.DTFC_RightBodyLiner table thead th.sorting_asc:after,
.DTFC_LeftBodyWrapper table thead th.sorting_desc:after,
.dataTables_scrollBody table thead th.sorting_desc:after,
.DTFC_RightBodyLiner table thead th.sorting_desc:after {
  display: none !important;
}
/* Tablesaw Tables */
.tablesaw thead {
  background: rgba(243, 243, 243, 0.7);
  background-image: none;
  border: none;
}
.tablesaw thead th {
  text-shadow: none;
  letter-spacing: 0.06em;
}
.tablesaw thead tr:first-child th {
  font-weight: 600;
  border: none;
  font-family: 'Hind Madurai', sans-serif;
}
.tablesaw td {
  border-top: 1px solid rgba(243, 243, 243, 0.7) !important;
}
.tablesaw td,
.tablesaw tbody th {
  font-size: inherit;
  line-height: inherit;
  padding: 10px !important;
}
.tablesaw-stack tbody tr,
.tablesaw tbody tr {
  border-bottom: none;
}
.tablesaw-sortable .tablesaw-sortable-head.tablesaw-sortable-ascending button:after,
.tablesaw-sortable .tablesaw-sortable-head.tablesaw-sortable-descending button:after {
  font-family: FontAwesome;
  font-size: 10px;
}
.tablesaw-sortable .tablesaw-sortable-head.tablesaw-sortable-ascending button:after {
  content: "\f176";
}
.tablesaw-sortable .tablesaw-sortable-head.tablesaw-sortable-descending button:after {
  content: "\f175";
}
.tablesaw-bar .btn-select.btn-small:after,
.tablesaw-bar .btn-select.btn-micro:after {
  font-size: 8px;
  padding-right: 10px;
}
.tablesaw-swipe .tablesaw-cell-persist {
  box-shadow: none;
}
.tablesaw-enhanced .tablesaw-bar .btn {
  text-shadow: none;
  background-image: none;
}
.tablesaw-enhanced .tablesaw-bar .btn.btn-select:hover {
  background: #ffffff;
}
.tablesaw-enhanced .tablesaw-bar .btn:hover,
.tablesaw-enhanced .tablesaw-bar .btn:focus,
.tablesaw-enhanced .tablesaw-bar .btn:active {
  color: #7fc1fc !important;
  background-color: #f3f3f3;
  outline: none !important;
  box-shadow: none !important;
  background-image: none;
}
/* Table colored */
.table-colored thead th {
  color: #ffffff;
}
.table-colored.table-custom thead th {
  background-color: #7fc1fc;
}
.table-colored.table-primary thead th {
  background-color: #188ae2;
}
.table-colored.table-success thead th {
  background-color: #4bd396;
}
.table-colored.table-info thead th {
  background-color: #3ac9d6;
}
.table-colored.table-warning thead th {
  background-color: #f9c851;
}
.table-colored.table-danger thead th {
  background-color: #f5707a;
}
.table-colored.table-inverse thead th {
  background-color: #3b3e47;
}
.table-colored.table-pink thead th {
  background-color: #f06292;
}
.table-colored.table-purple thead th {
  background-color: #6b5fb5;
}
.table-colored.table-brown thead th {
  background-color: #8d6e63;
}
.table-colored.table-orange thead th {
  background-color: #ff9800;
}
.table-colored.table-teal thead th {
  background-color: #26a69a;
}
/* Table colored-bordered */
.table-colored-bordered thead th {
  border-bottom: 0 !important;
  color: #ffffff;
}
.table-colored-bordered.table-bordered-primary {
  border: 2px solid #188ae2;
}
.table-colored-bordered.table-bordered-primary thead th {
  background-color: #188ae2;
}
.table-colored-bordered.table-bordered-success {
  border: 2px solid #4bd396;
}
.table-colored-bordered.table-bordered-success thead th {
  background-color: #4bd396;
}
.table-colored-bordered.table-bordered-info {
  border: 2px solid #3ac9d6;
}
.table-colored-bordered.table-bordered-info thead th {
  background-color: #3ac9d6;
}
.table-colored-bordered.table-bordered-custom {
  border: 2px solid #7fc1fc;
}
.table-colored-bordered.table-bordered-custom thead th {
  background-color: #7fc1fc;
}
.table-colored-bordered.table-bordered-warning {
  border: 2px solid #f9c851;
}
.table-colored-bordered.table-bordered-warning thead th {
  background-color: #f9c851;
}
.table-colored-bordered.table-bordered-danger {
  border: 2px solid #f5707a;
}
.table-colored-bordered.table-bordered-danger thead th {
  background-color: #f5707a;
}
.table-colored-bordered.table-bordered-inverse {
  border: 2px solid #3b3e47;
}
.table-colored-bordered.table-bordered-inverse thead th {
  background-color: #3b3e47;
}
.table-colored-bordered.table-bordered-pink {
  border: 2px solid #f06292;
}
.table-colored-bordered.table-bordered-pink thead th {
  background-color: #f06292;
}
.table-colored-bordered.table-bordered-purple {
  border: 2px solid #6b5fb5;
}
.table-colored-bordered.table-bordered-purple thead th {
  background-color: #6b5fb5;
}
.table-colored-bordered.table-bordered-orange {
  border: 2px solid #ff9800;
}
.table-colored-bordered.table-bordered-orange thead th {
  background-color: #ff9800;
}
.table-colored-bordered.table-bordered-brown {
  border: 2px solid #8d6e63;
}
.table-colored-bordered.table-bordered-brown thead th {
  background-color: #8d6e63;
}
.table-colored-bordered.table-bordered-teal {
  border: 2px solid #26a69a;
}
.table-colored-bordered.table-bordered-teal thead th {
  background-color: #26a69a;
}
/* Table full colored */
.table-colored-full {
  color: #ffffff;
}
.table-colored-full thead th {
  border-bottom: 0 !important;
  padding: 10px !important;
}
.table-colored-full tbody td,
.table-colored-full tbody th {
  border: 0 !important;
}
.table-colored-full.table-full-primary {
  background-color: rgba(24, 138, 226, 0.8);
}
.table-colored-full.table-full-primary thead th {
  background-color: #188ae2;
}
.table-colored-full.table-full-primary tbody tr:hover {
  background-color: #188ae2;
}
.table-colored-full.table-full-success {
  background-color: rgba(75, 211, 150, 0.8);
}
.table-colored-full.table-full-success thead th {
  background-color: #4bd396;
}
.table-colored-full.table-full-success tbody tr:hover {
  background-color: #4bd396;
}
.table-colored-full.table-full-info {
  background-color: rgba(58, 201, 214, 0.8);
}
.table-colored-full.table-full-info thead th {
  background-color: #3ac9d6;
}
.table-colored-full.table-full-info tbody tr:hover {
  background-color: #3ac9d6;
}
.table-colored-full.table-full-custom {
  background-color: rgba(127, 193, 252, 0.8);
}
.table-colored-full.table-full-custom thead th {
  background-color: #7fc1fc;
}
.table-colored-full.table-full-custom tbody tr:hover {
  background-color: #7fc1fc;
}
.table-colored-full.table-full-warning {
  background-color: rgba(249, 200, 81, 0.8);
}
.table-colored-full.table-full-warning thead th {
  background-color: #f9c851;
}
.table-colored-full.table-full-warning tbody tr:hover {
  background-color: #f9c851;
}
.table-colored-full.table-full-danger {
  background-color: rgba(245, 112, 122, 0.8);
}
.table-colored-full.table-full-danger thead th {
  background-color: #f5707a;
}
.table-colored-full.table-full-danger tbody tr:hover {
  background-color: #f5707a;
}
.table-colored-full.table-full-inverse {
  background-color: rgba(59, 62, 71, 0.8);
}
.table-colored-full.table-full-inverse thead th {
  background-color: #3b3e47;
}
.table-colored-full.table-full-inverse tbody tr:hover {
  background-color: #3b3e47;
}
.table-colored-full.table-full-pink {
  background-color: rgba(240, 98, 146, 0.8);
}
.table-colored-full.table-full-pink thead th {
  background-color: #f06292;
}
.table-colored-full.table-full-pink tbody tr:hover {
  background-color: #f06292;
}
.table-colored-full.table-full-purple {
  background-color: rgba(107, 95, 181, 0.8);
}
.table-colored-full.table-full-purple thead th {
  background-color: #6b5fb5;
}
.table-colored-full.table-full-purple tbody tr:hover {
  background-color: #6b5fb5;
}
.table-colored-full.table-full-brown {
  background-color: rgba(141, 110, 99, 0.8);
}
.table-colored-full.table-full-brown thead th {
  background-color: #8d6e63;
}
.table-colored-full.table-full-brown tbody tr:hover {
  background-color: #8d6e63;
}
.table-colored-full.table-full-orange {
  background-color: rgba(255, 152, 0, 0.8);
}
.table-colored-full.table-full-orange thead th {
  background-color: #ff9800;
}
.table-colored-full.table-full-orange tbody tr:hover {
  background-color: #ff9800;
}
.table-colored-full.table-full-teal {
  background-color: rgba(38, 166, 154, 0.8);
}
.table-colored-full.table-full-teal thead th {
  background-color: #26a69a;
}
.table-colored-full.table-full-teal tbody tr:hover {
  background-color: #26a69a;
}
/* =====================================
   CHARTS
   ===================================== */
.chart-detail-list li {
  margin: 0 10px;
}
/* Flot chart */
#flotTip {
  padding: 8px 12px;
  background-color: #313a46 !important;
  z-index: 100;
  color: #ffffff;
  opacity: 0.9;
  font-size: 13px;
  -webkit-border-radius: 3px !important;
  -moz-border-radius: 3px !important;
  border-radius: 3px !important;
}
.legend tr {
  height: 20px;
  font-family: 'Hind Madurai', sans-serif;
}
.legendLabel {
  padding-left: 5px !important;
  line-height: 10px;
  padding-right: 10px;
}
/* Morris chart */
.morris-hover.morris-default-style {
  border-radius: 5px;
  padding: 10px 12px;
}
/* Chartist chart */
.ct-golden-section:before {
  float: none;
}
.ct-chart {
  max-height: 300px;
}
.ct-chart .ct-label {
  fill: #a3afb7;
  color: #a3afb7;
  font-size: 12px;
  line-height: 1;
}
.ct-grid {
  stroke: rgba(49, 58, 70, 0.1);
}
.ct-chart.simple-pie-chart-chartist .ct-label {
  color: #ffffff;
  fill: #ffffff;
  font-size: 16px;
}
.ct-chart .ct-series.ct-series-a .ct-bar,
.ct-chart .ct-series.ct-series-a .ct-line,
.ct-chart .ct-series.ct-series-a .ct-point,
.ct-chart .ct-series.ct-series-a .ct-slice-donut {
  stroke: #3ac9d6;
}
.ct-chart .ct-series.ct-series-b .ct-bar,
.ct-chart .ct-series.ct-series-b .ct-line,
.ct-chart .ct-series.ct-series-b .ct-point,
.ct-chart .ct-series.ct-series-b .ct-slice-donut {
  stroke: #f5707a;
}
.ct-chart .ct-series.ct-series-c .ct-bar,
.ct-chart .ct-series.ct-series-c .ct-line,
.ct-chart .ct-series.ct-series-c .ct-point,
.ct-chart .ct-series.ct-series-c .ct-slice-donut {
  stroke: #4bd396;
}
.ct-chart .ct-series.ct-series-d .ct-bar,
.ct-chart .ct-series.ct-series-d .ct-line,
.ct-chart .ct-series.ct-series-d .ct-point,
.ct-chart .ct-series.ct-series-d .ct-slice-donut {
  stroke: #ff9800;
}
.ct-chart .ct-series.ct-series-e .ct-bar,
.ct-chart .ct-series.ct-series-e .ct-line,
.ct-chart .ct-series.ct-series-e .ct-point,
.ct-chart .ct-series.ct-series-e .ct-slice-donut {
  stroke: #313a46;
}
.ct-chart .ct-series.ct-series-f .ct-bar,
.ct-chart .ct-series.ct-series-f .ct-line,
.ct-chart .ct-series.ct-series-f .ct-point,
.ct-chart .ct-series.ct-series-f .ct-slice-donut {
  stroke: #188ae2;
}
.ct-chart .ct-series.ct-series-g .ct-bar,
.ct-chart .ct-series.ct-series-g .ct-line,
.ct-chart .ct-series.ct-series-g .ct-point,
.ct-chart .ct-series.ct-series-g .ct-slice-donut {
  stroke: #8d6e63;
}
.ct-series-a .ct-area,
.ct-series-a .ct-slice-pie {
  fill: #3ac9d6;
}
.ct-series-b .ct-area,
.ct-series-b .ct-slice-pie {
  fill: #f5707a;
}
.ct-series-c .ct-area,
.ct-series-c .ct-slice-pie {
  fill: #4bd396;
}
.ct-series-d .ct-area,
.ct-series-d .ct-slice-pie {
  fill: #ff9800;
}
.chartist-tooltip {
  position: absolute;
  display: inline-block;
  opacity: 0;
  min-width: 10px;
  padding: 2px 10px;
  border-radius: 3px;
  background: #313a46;
  color: #ffffff;
  text-align: center;
  pointer-events: none;
  z-index: 1;
  -webkit-transition: opacity .2s linear;
  -moz-transition: opacity .2s linear;
  -o-transition: opacity .2s linear;
  transition: opacity .2s linear;
}
.chartist-tooltip.tooltip-show {
  opacity: 1;
}
/* C3 chart */
.c3 svg {
  max-width: 100%;
}
.c3-tooltip td > span {
  background: #313a46;
}
.c3-tooltip td {
  border-left: none;
}
.c3-tooltip {
  box-shadow: 0 8px 40px 0 rgba(0, 0, 0, 0.12);
  opacity: 1;
}
.c3-chart-arcs-title {
  font-size: 18px;
  font-weight: 600;
  font-family: 'Hind Madurai', sans-serif;
}
.c3-tooltip tr {
  border: none !important;
}
.c3-tooltip th {
  background-color: #313a46;
}
.c3-tooltip .value {
  font-weight: 600;
  font-family: 'Hind Madurai', sans-serif;
}
/* Sparkline chart */
.jqstooltip {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  width: auto !important;
  height: auto !important;
  background-color: #313a46 !important;
  padding: 5px 10px !important;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  border-color: #313a46 !important;
}
.jqsfield {
  font-size: 12px !important;
  line-height: 18px !important;
}
/* Google Chart*/
.google-chart .chart {
  display: inline-block;
}
